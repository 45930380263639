import { useParams } from "react-router-dom";
import ProductEdit from "../../../Forms/ProductEdit";
import useRequest from "../../../Hooks/useRequest";
import { getProductDetail } from "../../../Service/console";
import { Space, Skeleton } from "antd";

const ProductDetail = () => {
  const { productId } = useParams();
  
  const {
    data = {},
    status,
    update,
  } = useRequest(getProductDetail, [productId]);
  
  return (
    <Skeleton paragraph={{ rows: 6 }} loading={status === "pending"}>
      <ProductEdit data={data} />
    </Skeleton>
  );
};

export default ProductDetail;
