import { useContext, useState } from 'react'
import { Row, Col, Alert } from 'antd'
import './index.scss'
import LoginForm from '../../Forms/Login'
import { AuthContext } from '../../Context/AuthContext'
import useAuthentication from '../../Hooks/useAuthantication'
import { Navigate } from 'react-router-dom'
function Login() {
  const { login, LoginStatu } = useContext(AuthContext)
  const [errorMessage, seterrorMessage] = useState("")
  const auth = useAuthentication();
  const onLogin = ({ email, password }) => {
    seterrorMessage("")
    login(email, password).catch(err => {
      seterrorMessage(err)
    })
  }
  if (auth.isLoggedIn) {
    return <Navigate to="/" />;
  }
  return (
    <Row className="Login">
      <Col span={12} offset={6}>
        <div style={{ maxWidth: 500, margin: "auto" }}>
        <div className="Logo" />
        <LoginForm onSubmit={onLogin} status={LoginStatu} />
        {
          LoginStatu === 'error' &&
          <Alert
            message="Hata"
            description={errorMessage}
            type="error"
            showIcon
          />
        }
        </div>
      </Col>
    </Row>
  )
}

export default Login
