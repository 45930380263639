import { notification } from "antd";
import axios from "axios";

function responseF(response) {
  if (
    response.headers['content-type'] === 'application/ms-excel' ||
    response.headers['content-type'] ===
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    response.headers['content-type'] ===
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ) {
    var today = new Date();
    var date = today.getFullYear()+"-"+(today.getMonth()+1)+"-"+today.getDate();
    var fileHeader = response.config.url;
    fileHeader = fileHeader.substring(9);
    if (fileHeader === "Customer/export") {
      fileHeader = "customer_";
    }
    else if (fileHeader === "Product/export-product-click") {
      fileHeader = "productclick_";
    }
    else if (fileHeader === "DemandedProduct/export") {
      fileHeader = "basketandorder_";
    }
    var filename = fileHeader + date + ".xlsx";
    var disposition = response.headers['content-disposition'];
    if (disposition) {
      var filenameRegex = /filename[^;=\n]=((['"]).?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return Promise.resolve({
      fileName: `${filename}`,
      file: response.data,
      contentType: response.headers['content-type']
    });
  }
  if (response?.data?.result) {
    return response.data.result;
  }
}
function errorF(error) {
  if (error.response && error.response.status === 401 &&
    error.response.config.url !== '/console/Auth/login') {
    logout();
    return Promise.reject("Bir hata oluştu.");
  }

  if (error?.response?.data?.errors) {
    notification.error({
      message: "Hata",
      description: error.response.data.errors.join(", ")
    });
    return Promise.reject(error.response.data.errors);
  }
  return Promise.reject("Bir şeyler ters gitti.");
}

export const logout = () => {
  localStorage.clear();
  window.location.reload();
};

const apiService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
});

export function setAutHeader(token) {
  if (typeof token === "string") {
    apiService.defaults.headers.common["Authorization"] = `${token}`;
  } else {
    delete apiService.defaults.headers.common["Authorization"];
  }
}
export function isLoggedin() {
  if (localStorage.user) {
    const user = JSON.parse(localStorage.user);
    if (user.token) {
      setAutHeader(user.token.accessToken);
      return true;
    } else {
      setAutHeader(false);
      return false;
    }
  }
  return false;
}
apiService.interceptors.response.use(responseF, errorF);
export default apiService;


export const downloadFile = response => {
  try {

    const blob = new Blob([response.file], { type: response.contentType });
    let dom = document.createElement('a');
    let url = window.URL.createObjectURL(blob);
    dom.href = url;
    dom.download = decodeURI(response.fileName);
    dom.style.display = 'none';
    document.body.appendChild(dom);
    dom.click();
    dom.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error)
  }
};