import { Collapse, Table, Button } from "antd";
import useRequest from "../../Hooks/useRequest";
import { formatPrice } from "../../utils/price";
import Filters from "./Filters";
import { getProductList } from "../../Service/console";
import usePagination from "../../Hooks/usePagination";
import { NavLink } from "react-router-dom";

const { Panel } = Collapse;
const ProductsTable = () => {
  const [params, setParams] = usePagination({
    ProductName: "",
    SerialNumber: "",
    OrderNumber: undefined,
  });

  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(getProductList, [params]);

  const pricePeriods = {
    10: "Günlük",
    20: "Haftalık",
    30: "Aylık",
    40: "3 Aylık",
    50: "6 Aylık",
    60: "12 Aylık",
  };

  const Columns = [
    {
      key: "medias",
      title: "Görsel",
      dataIndex: "medias",
      render: (media) => <img width={50} src={media[0].url} />,
    },
    {
      key: "productId",
      title: "productId",
      dataIndex: "productId",
    },
    {
      key: "name",
      title: "Adı",
      dataIndex: "name",
    },
    {
      key: "prices",
      title: "Fiyatlar",
      dataIndex: "prices",
      render: (prices) => {
        const initialValue = "";
        const resp = prices
          .sort((a, b) => a.priceType - b.priceType)
          .reverse()
          .reduce(
            (accumulator, currentValue) =>
              `${
                pricePeriods[currentValue.priceType] +
                " : " +
                formatPrice(currentValue.newPrice)
              } <br> ${accumulator}`,
            initialValue
          );
        return <div dangerouslySetInnerHTML={{ __html: resp }} />;
      },
    },
    {
      key: "stock",
      title: "Stok",
      dataIndex: "variants",
      render: (variants) => variants[0].salableQuantity,
    },
    {
      key: "actions",
      title: "İşlemler",
      render: (_, Record) => {
        return (
          <div> 
            <NavLink to={`/Products/${Record.productId}`}>
              <Button type="primary">Düzenle</Button>
            </NavLink>
            <Button
              type="primary"
              style={{
                background: "#dc3545",
                borderColor: "#dc3545",
                color: "#ffffff",
                marginLeft : "10px"
              }}
            >
              Sil
            </Button>
          </div>
        );
      },
    },
  ]; 

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setParams({ Size: pageSize });
    },
    onChange: (page) => {
      setParams({ Index: page });
    },
    pageSizeOptions: params.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };

  return (
    <>
      <Collapse>
        <Panel header="Import & Export">
          <Filters />
        </Panel>
      </Collapse>
      <Table
        loading={status === "pending"}
        dataSource={status === "success" ? data.data : []}
        columns={Columns}
        pagination={pagination}
        rowKey={"serialNumber"}
      />
    </>
  );
};

export default ProductsTable;
