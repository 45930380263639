import PeriodicSalesReportTable from "../../Tables/periodic-sales-report"


const PeriodicSalesReport = () => {

  return (
    <div>
      <PeriodicSalesReportTable />
    </div>
  )
}

export default PeriodicSalesReport
