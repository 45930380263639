import { Button, Collapse, Table, Row, Col } from "antd";
import useRequest from "../../Hooks/useRequest";
import Filters from "./Filters";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import usePagination from "../../Hooks/usePagination";
import { getRentalOrders } from "../../Service/console";
import {useEffect, useState} from "react";

const { Panel } = Collapse;
const CurrentRentalReportTable = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const [initialValues, setInitialValues] = useState({});
  const navigate = useNavigate();
  const [params, setParams] = usePagination({
    ProductName: "",
    ProductId: "",
    SerialNumber: "",
  });

  useEffect(() => {
    const data = {};
    for (const [key, value] of Object.entries(params)) {
      if(String(query?.get(key)) !== "undefined" && String(query?.get(key)) !== "null"){
        data[key] = query?.get(key)
      }
    }

    setInitialValues({
      ...data,
    })
  }, []);

  useEffect(() => {
    setParams({
      ...params,
      ...initialValues,
    })
  }, [initialValues]);

  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(getRentalOrders, [params]);

  const Columns = [
    {
      key: "productId",
      title: "Ürün ID",
      dataIndex: "productId",
    },
    {
      key: "productName",
      title: "Ürün Adı",
      dataIndex: "productName",
    },
    {
      key: "orderCount",
      title: "Güncel Kiralama Sayısı",
      dataIndex: "orderCount",
    },
    {
      key: "providerType",
      title: "Ürün kaynağı",
      dataIndex: "providerType",
    },
    {
      title: "Detay",
      dataIndex: "",
      fixed: "right",
      key: "x",
      render: (_, Record) => (
        <NavLink to={`/current-rental-report/${Record.productId}`}>
          <Button type="primary">Detay</Button>
        </NavLink>
      ),
    },
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setParams({ Size: pageSize });
    },
    onChange: (page) => {
      setParams({ Index: page });
    },
    pageSizeOptions: params.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };
  const onFilter = (values) => {
    const queryString = '?' + new URLSearchParams(values).toString();
    navigate(queryString);
    setParams({
      ...values,
      Index: 1,
      Size: 10
    });
  };

  
  return (
    <>
      <Collapse>
        <Panel header="Filtreler">
          <Filters initialValues={initialValues} onSubmit={onFilter} />
        </Panel>
      </Collapse>
      <Table
        loading={status === "pending"}
        dataSource={status === "success" ? data.data : []}
        columns={Columns}
        pagination={pagination}
        rowKey={"serialNumber"}
      />
    </>
  );
};

export default CurrentRentalReportTable;
