import { Form, Input, DatePicker, Button } from 'antd'
import React from 'react'
import { formatDate } from "../../utils/date"; 

function Filters({ onSubmit }) {
  const onFinish = (value) => {
    onSubmit({
      ...value,
      StartDate: value.StartDate ? formatDate(value.StartDate,false,"") : undefined,
    })
  }
  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      onFinish={onFinish}
    >
      <Form.Item label="Başlangıç tarihi" name="StartDate">
        <DatePicker />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Çıktıyı İndir
        </Button>
      </Form.Item>
    </Form>
  )
}


export default Filters
