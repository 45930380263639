import { Form, Input, DatePicker, Button } from 'antd'
import React from 'react'

function Filters({ onSubmit }) {
  const onFinish = (value) => {
    onSubmit({
      ...value,
    })
  }
  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      onFinish={onFinish}
    >
       <Form.Item label="Ürün Adı" name="Name">
        <Input />
      </Form.Item>
      <Form.Item label="Görüntülenme Sayısı" name="ViewCount">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Filtrele
        </Button>
      </Form.Item>
    </Form>
  )
}


export default Filters
