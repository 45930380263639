import {Form, Select, Input, DatePicker, Button, Col, Row} from "antd";
import React, {useContext, useState} from "react";
import {OrderContext} from "../../Context/OrderContex";
import {formatDate} from "../../utils/date";
import {useLocation} from "react-router-dom";
import moment from "moment";
import {getProductListWithFilter} from "../../Service/console";

const {Option} = Select;

const PaymentStatuses = {
    data: [
        {id: 0, value: "Bekliyor"},
        {id: 1, value: "Ödendi"},
        {id: 2, value: "Hatalı"},
        {id: 3, value: "Parçalı İade"},
        {id: 4, value: "İade"},
        {id: 5, value: "Voided"},
        {id: 6, value: "Provision"}
    ]
};

const YesOrNo = {
    data: [
        {id: 0, value: false, label: "Hayır"},
        {id: 1, value: true, label: "Evet"},
    ]
};

function Filters({onSubmit, initialValues}) {
    const {OrderItemStatuses} = useContext(OrderContext);

    const onFinish = (value) => {
        onSubmit({
            ...value,
            //OrderStatuses: getOrderStatus(value),
            //PaymentStatuses: getPaymentStatus(value),
            StartDate: value.StartDate ? formatDate(value.StartDate, false, "") : undefined,
            MinStartDate: value.MinStartDate ? formatDate(value.MinStartDate, false, "") : undefined,
            MaxStartDate: value.MaxStartDate ? formatDate(value.MaxStartDate, false, "") : undefined,
            MinEndDate: value.MinEndDate ? formatDate(value.MinEndDate, false, "") : undefined,
            MaxEndDate: value.MaxEndDate ? formatDate(value.MaxEndDate, false, "") : undefined,
            EndDate: value.EndDate ? formatDate(value.EndDate, false, "") : undefined
        });
    };

  const [productList,setProductList] = useState([])
  const [isSelectLoading,setIsSelectLoading] = useState(false)

  const onSearch = async (value) => {
    setIsSelectLoading(true)
    await getProductListWithFilter({
      query: value,
      filterOrderByType: 0,
      pageIndex: 1,
      pageSize: 1000,
      brandIds: []
    }).then(resp => {
      setProductList(resp?.data)
      setIsSelectLoading(false)
    }).catch(err => {
      setIsSelectLoading(false)
      return err
    })
  };

    /* const getOrderStatus = (value) => {
      if (value.OrderStatuses) {
        return value.OrderStatuses.find((OrderStatus) => OrderItemStatuses.data.find((Orderstat) => Orderstat.id === parseInt(OrderStatus)).id);
      }
    };

    const getPaymentStatus = (value) => {
      if (value.PaymentStatuses) {
        return value.PaymentStatuses.find((PaymentStatus) => PaymentStatuses.data.find((Paymentstat) => Paymentstat.id === parseInt(PaymentStatus)).id);
      }
    }; */


    return (
        <Form labelCol={{span: 6}}
              wrapperCol={{span: 14}}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={initialValues}
        >
            <div className="filters">
                <Row>
                    <Col md={12}>
                        <Form.Item label="İsim" name="BillingAddressFirstName">
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Soyisim" name="BillingAddressLastName">
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Item label="Email" name="BillingAddressEmail">
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Sipariş no" name="OrderNumber">
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Item label="Başl. Tarihi" name="StartDate">
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Bitiş Tarihi" name="EndDate">
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Item label="Min Başl." name="MinStartDate">
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Max Başl." name="MaxStartDate">
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Item label="Min Bitiş" name="MinEndDate">
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Max Bitiş" name="MaxEndDate">
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Item label="Sipariş dur:" name="OrderStatuses">
                            <Select style={{width: "100%"}} placeholder="Lütfen Seçin">
                                {OrderItemStatuses.data.map((orderStatus) => (
                                    <Option key={orderStatus.id}>{orderStatus.value}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Ödeme dur:" name="PaymentStatuses">
                            <Select style={{width: "100%"}} placeholder="Lütfen Seçin">
                                {PaymentStatuses.data.map((PaymentStatus) => (
                                    <Option key={PaymentStatus.id}>{PaymentStatus.value}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>

                    <Col md={12}>
                        <Form.Item label="Satın alma siparişleri :" name="IsPurchaseOrder">
                            <Select style={{width: "100%"}} placeholder="Lütfen Seçin">
                                {YesOrNo.data.map((item) => (
                                    <Option value={item.value} key={item.id}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col md={12}>
                        <Form.Item label="Uzatma siparişleri :" name="IsRentalOrder">
                            <Select style={{width: "100%"}} placeholder="Lütfen Seçin">
                                {YesOrNo.data.map((item) => (
                                    <Option value={item.value} key={item.id}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>

                    <Col md={12}>
                        <Form.Item label="Abonelik siparişleri :" name="IsSubscriptionOrder">
                            <Select style={{width: "100%"}} placeholder="Lütfen Seçin">
                                {YesOrNo.data.map((item) => (
                                    <Option value={item.value} key={item.id}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Seri Numarası" name="SerialNumber">
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Item label="Ürün:" name="productIds">
                      <Select
                          mode="tags"
                          style={{ width: "100%" }}
                          showSearch
                          loading={isSelectLoading}
                          onSearch={onSearch}
                          placeholder="Lütfen ürün arayın"
                          filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }

                      >
                        {productList?.map((list) => (
                            <Option key={list?.variantDetailResponses[0]?.variantId}>{list?.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
            </div>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Filtrele
                </Button>
            </Form.Item>
        </Form>
    );
}

export default Filters;
