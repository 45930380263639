import Orders from "../../Tables/Orders"


const Order = () => {
 
  return (
    <div>
    <Orders />

    </div>
  )
}

export default Order
