import { notification } from "antd";

export const notify = (status) => {
  return notification[status]({
    message:
      "İşlem " +
      (status === "info"
        ? "Bekleniyor"
        : status === "error"
        ? "Başarısız!!"
        : "Başarılı.."),
  });
};
