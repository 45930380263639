import ProductClickReportTable from "../../Tables/product-click-report"


const ProductClickReport = () => {

  return (
    <div>
      <ProductClickReportTable />
    </div>
  )
}

export default ProductClickReport
