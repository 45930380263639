import {Modal, Tag} from "antd";
import React from "react";
import data from "../jsons/transactions.json"

function PaymentHistoryModal({visible, onCancel, order, product}) {
    const transactions = order.transactions.filter(transaction => transaction.orderItemId === product.id);

    return (
        <Modal
            title="Ödeme Geçmişi"
            visible={visible}
            footer={null}
            onCancel={onCancel}
            destroyOnClose
        >
            <div id="paymentHistoryModal">
                <ul className="list">
                    {transactions.map((item, i) => (
                        <li key={i} className={`listItem ${item.status !== 3 && "disabled"}`}>
                            <div className="date">{item.subscriptionDate}</div>
                            <div className="priceWrapper">
                                {item.subscriptionStatusText && !['Onay bekliyor'].includes(item.subscriptionStatusText) && (
                                    <span className="status">
                                        <Tag color={['İptal edildi', 'Başarısız ödeme'].includes(item.subscriptionStatusText) ? 'red' : 'green'} >{item.subscriptionStatusText}</Tag>
                                    </span>
                                )}
                                <span className="price">{item.monthlySubscriptionAmountText} {item.currencyCode}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </Modal>
    );
}

export default PaymentHistoryModal;
