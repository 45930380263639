import React from 'react'
import {
  useRoutes
} from "react-router-dom";
import Routes from './Routes'

function Router() {
  let element = useRoutes(Routes);
  return (
    element
  )
}

export default Router
