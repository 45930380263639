import DemandedProductReportTable from "../../Tables/demanded-product-report"


const DemandedProductReport = () => {

  return (
    <div>
      <DemandedProductReportTable />
    </div>
  )
}

export default DemandedProductReport
