import {Form, Upload, Button, Row, Col, Descriptions, notification, Space, Input, Select, Checkbox} from "antd";
import React, {useEffect, useState} from "react";
import {DownloadOutlined, UploadOutlined} from "@ant-design/icons/lib/icons";
import {
    sampleImportForNewVariantSerialNumber,
    sampleImportForUpdateVariantSerialNumber,
    variantSerialNumberImport,
    variantSerialNumberUpdateImport,
    getProductListWithFilter
} from "../../Service/console";
import {downloadFile} from "../../Service";
import {notify} from "../../utils/notify";


const { Option } = Select;

function Filters({ onSubmit,initialValues }) {
    const [productList,setProductList] = useState([])
    const [isSelectLoading,setIsSelectLoading] = useState(false)

    const onFinish = (value) => onSubmit({...value,});


    const close = () => {
        console.log(
            'Vazgeçildi',
        );
    };

    const openNotification = ({file, onSuccess}, func) => {
        const key = `open${Date.now()}`;
        const btn = (
            <Space>
                <Button type="link" size="small" onClick={() => notification.destroy()}>
                    Vazgeç
                </Button>
                <Button type="primary" size="small" onClick={() => {
                    func({file, onSuccess})
                    notification.destroy()
                }}>
                    Yükle
                </Button>
            </Space>
        );


        notification.info({
            message: 'Emin misiniz?',
            description:
                'Yükleme yapmak istediğinizden emin misiniz?',
            btn,
            key,
            onClose: close,
            placement: "topLeft"
        });
    };


    const uploadOptions = {
        name: "file",
        showUploadList: false,
        accept: ".xls,.xlsx",
    };

    const sendVariantSerialNumberImport = ({file, onSuccess}) => {

        const fmData = new FormData();
        fmData.append("file", file);

        notify("info");
        variantSerialNumberImport(fmData)
            .then((res) => {
                notify("success");
            })
            .catch(() => {
                notify("error");
            });
    }


    const sendImportForUpdateVariantSerialNumber = ({file, onSuccess}) => {

        const fmData = new FormData();
        fmData.append("file", file);

        notify("info");
        variantSerialNumberUpdateImport(fmData)
            .then((res) => {
                notify("success");
            })
            .catch(() => {
                notify("error");
            });
    }

    const _demandedExport = (exportType) => {
        notify("info");
        exportType()
            .then((res) => {
                downloadFile(res);
                notify("success");
            })
            .catch(() => {
                notify("error");
            });
    };

    const onSearch = async (value) => {
        setIsSelectLoading(true)
        await getProductListWithFilter({
            query: value,
            filterOrderByType: 0,
            pageIndex: 1,
            pageSize: 1000,
            brandIds: []
        }).then(resp => {
            setProductList(resp?.data)
            setIsSelectLoading(false)
        }).catch(err => {
            setIsSelectLoading(false)
            return err
        })
    };

    return (
        <Form
            labelCol={{span: 4}}
            wrapperCol={{span: 14}}
            layout="horizontal"
            id="periodicSalesReportFilterForm"
            onFinish={onFinish}
            initialValues={initialValues}
        >
            <Row align="center">
                <Col lg={6} md={12}>
                    <Descriptions title="Variant Serial Number Ekleme"/>
                    <Form.Item>
                        <Upload {...uploadOptions}
                                customRequest={({file, onSuccess}) => openNotification({
                                    file,
                                    onSuccess
                                }, sendVariantSerialNumberImport)}>
                            <Button
                                type="primary"
                                style={{
                                    background: "#fd7e14",
                                    borderColor: "#fd7e14",
                                    color: "#ffffff",
                                }}
                                shape="round"
                                icon={<UploadOutlined/>}
                            >
                                Excel Ekle
                            </Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            style={{
                                background: "#fd7e14",
                                borderColor: "#fd7e14",
                                color: "#ffffff",
                            }}
                            shape="round"
                            onClick={() => _demandedExport(sampleImportForNewVariantSerialNumber)}
                            icon={<DownloadOutlined/>}
                        >
                            Örnek Çıktıyı İndir
                        </Button>
                    </Form.Item>
                </Col>
                <Col lg={6} md={12}>
                    <Descriptions title="Variant Serial Number Güncelleme"/>
                    <Form.Item>
                        <Upload {...uploadOptions} customRequest={({file, onSuccess}) => openNotification({
                            file,
                            onSuccess
                        }, sendImportForUpdateVariantSerialNumber)}>
                            <Button
                                type="primary"
                                style={{
                                    background: "#0d6efd",
                                    borderColor: "#0d6efd",
                                    color: "#ffffff",
                                }}
                                shape="round"
                                icon={<UploadOutlined/>}
                            >
                                Excel Ekle
                            </Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            style={{
                                background: "#0d6efd",
                                borderColor: "#0d6efd",
                                color: "#ffffff",
                            }}
                            shape="round"
                            onClick={() => _demandedExport(sampleImportForUpdateVariantSerialNumber)}
                            icon={<DownloadOutlined/>}
                        >
                            Örnek Çıktıyı İndir
                        </Button>
                    </Form.Item>
                </Col>
                <Col lg={24} md={12}>
                    <Row>
                        <Col md={12}>
                            <Form.Item label="Serial Number" name="serialNumber">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item label="Ürün:" name="variantId">
                                <Select
                                    style={{ width: "100%" }}
                                    showSearch
                                    loading={isSelectLoading}
                                    onSearch={onSearch}
                                    placeholder="Lütfen ürün arayın"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    {productList?.map((list) => (
                                        <Option key={list?.variantDetailResponses[0]?.variantId}>{list?.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={4}>
                            <Form.Item name="allGetVariantSerialNumber" valuePropName="checked">
                                <Checkbox>Hepsini Getir</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col md={5}>
                            <Form.Item name="isPurchaseItem" valuePropName="checked">
                                <Checkbox>Satın Alınanları Getir</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Filtrele
                </Button>
            </Form.Item>
        </Form>
    );
}

export default Filters;
