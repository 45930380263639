import PurchaseOfferEvaluationReportTable from "../../Tables/get-purchase-offer-evaluation-report";


const PurchaseOfferEvaluationReport = () => {

  return (
    <div>
      <PurchaseOfferEvaluationReportTable />
    </div>
  )
}

export default PurchaseOfferEvaluationReport
