import {Button, Space, Input, Form, Modal, Select, Radio} from "antd";
import React from "react";
const { Option } = Select;
const { TextArea } = Input;

function OrderScoreModal({ visible, onFinish, onCancel, loading,data }) {

    const Scores = {
        data: [
            { id: 1, value: 1,label: "1" },
            { id: 2, value: 2,label: "2" },
            { id: 3, value: 3,label: "3" },
            { id: 4, value: 4,label: "4" },
            { id: 5, value: 5,label: "5" },
            { id: 6, value: 6,label: "6" },
            { id: 7, value: 7,label: "7" },
            { id: 8, value: 8,label: "8" },
            { id: 9, value: 9,label: "9" },
            { id: 10, value: 10,label: "10" },
        ]
    };

    return (
        <Modal
            title="Teslimat Puanlaması"
            visible={visible}
            footer={null}
            onCancel={onCancel}
            destroyOnClose
            width={750}
        >
            <Form
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                layout="horizontal"
                onFinish={onFinish}
            >
                <Form.Item
                    label="Sipariş No: "
                    name="OrderNumber"
                    initialValue={data?.orderNumber}
                    rules={[{ required: true, message: "Sipariş no zorunludur" }]}
                >
                    <Input disabled placeholder="OrderNumber" />
                </Form.Item>
                <Form.Item
                    label="Customer Id: "
                    name="CustomerId"
                    initialValue={data?.customerId}
                    rules={[{ required: true, message: "Customer Id zorunludur" }]}
                >
                    <Input disabled placeholder="CustomerId" />
                </Form.Item>
                <Form.Item label="Zamanında Gönderme" name="SendingOnTime" rules={[{ required: true, message: "SendingOnTime zorunludur" }]}>
                    <Radio.Group >
                        {Scores.data.map((item) => (
                            <Radio.Button key={item.id} value={item.value}>{item.label}</Radio.Button>
                        ))}
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Paketleme ve Kutu Durumu" name="PackagingAndBoxCondition" rules={[{ required: true, message: "PackagingAndBoxCondition zorunludur" }]}>
                    <Radio.Group >
                        {Scores.data.map((item) => (
                            <Radio.Button key={item.id} value={item.value}>{item.label}</Radio.Button>
                        ))}
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Parçaların Tamlığı" name="CompletenessOfParts" rules={[{ required: true, message: "CompletenessOfParts zorunludur" }]}>
                    <Radio.Group >
                        {Scores.data.map((item) => (
                            <Radio.Button key={item.id} value={item.value}>{item.label}</Radio.Button>
                        ))}
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Ürün Temizliği ve kozmetiği" name="ProductCleaningAndCosmetics" rules={[{ required: true, message: "ProductCleaningAndCosmetics zorunludur" }]}>
                    <Radio.Group >
                        {Scores.data.map((item) => (
                            <Radio.Button key={item.id} value={item.value}>{item.label}</Radio.Button>
                        ))}
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Not" name="Note" rules={[{ required: false }]}>
                    <TextArea rows={4} placeholder="Notunuz" />
                </Form.Item>
                <Form.Item>
                    <Space split>
                        <Button onClick={onCancel} loading={loading}>
                            İptal
                        </Button>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Kaydet
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default OrderScoreModal;
