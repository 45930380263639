import { Button, Collapse, Row, Col, Table, Tag } from "antd";
import { DownloadOutlined } from "@ant-design/icons/lib/icons";
import useRequest from "../../Hooks/useRequest";
import Filters from "./Filters";
import {
  orderInsuranceReport,
  orderInsuranceReportExport
} from "../../Service/report";
import { downloadFile } from "../../Service";
import usePagination from "../../Hooks/usePagination";
import { notify } from "../../utils/notify";
import {useLocation, useNavigate} from "react-router-dom";
import moment from "moment/moment";
import {useEffect, useState} from "react";
import {formatDate} from "../../utils/date";

const { Panel } = Collapse;
const OrderInsuranceReportTable = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const [initialValues, setInitialValues] = useState({});
  const navigate = useNavigate();
  const [Attributes, setAttributes] = usePagination({
    OrderId: undefined,
    OrderNumber: "",
    IsSendInsuranceMail: undefined,
    IsInsuranceStarted: undefined,
    MinCreatedAt: "",
    MaxCreatedAt: "",
  });

  useEffect(() => {
    const data = {};
    for (const [key, value] of Object.entries(Attributes)) {
      if(String(query?.get(key)) !== "undefined" && String(query?.get(key)) !== "null"){
        data[key] = query?.get(key)
      }
    }

    setInitialValues({
      ...data,
      MinCreatedAt: data.MinCreatedAt ? moment(data.MinCreatedAt,"YYYY-MM-DD") : undefined,
      MaxCreatedAt: data.MaxCreatedAt ? moment(data.MaxCreatedAt,"YYYY-MM-DD") : undefined
    })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setAttributes({
        ...Attributes,
        ...initialValues,
        MinCreatedAt: initialValues.MinCreatedAt ? formatDate(initialValues.MinCreatedAt,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
        MaxCreatedAt: initialValues.MaxCreatedAt ? formatDate(initialValues.MaxCreatedAt,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
      })
    },400)
  }, [initialValues]);

  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(orderInsuranceReport, [Attributes]);
  const Columns = [
    {
      key: "orderId",
      title: "Sipariş Id",
      dataIndex: "orderId",
      width: 70,
    },
    {
      key: "orderNumber",
      title: "Sipariş Numarası",
      dataIndex: "orderNumber",
      width: 100,
    },
    {
      key: "isInsuranceStarted",
      title: "Sigorta",
      dataIndex: "isInsuranceStarted",
      render: (isInsuranceStarted) => (
        <Tag color={isInsuranceStarted ? "geekblue" : "volcano"}>
          {isInsuranceStarted ? "Başladı" : "Başlamadı"}
        </Tag>
      ),
      width: 70,
    },
    {
      key: "isSendInsuranceMail",
      title: "Sigorta Maili",
      dataIndex: "isSendInsuranceMail",
      render: (isSendInsuranceMail) => (
        <Tag color={isSendInsuranceMail ? "geekblue" : "volcano"}>
          {isSendInsuranceMail ? "Gönderildi" : "Gönderilmedi"}
        </Tag>
      ),
      width: 70,
    },
    {
      key: "damageNote",
      title: "Hasar Notu",
      dataIndex: "damageNote",
      width: 200,
    },
    // {
    //   title: "Detay",
    //   dataIndex: "",
    //   fixed: "right",
    //   key: "x",
    //   render: (_, Record) => (
    //     <NavLink to={`/Customer/${Record.id}`}>
    //       <Button type="primary">Detay</Button>
    //     </NavLink>
    //   ),
    // },
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setAttributes({ Size: pageSize });
    },
    onChange: (page) => {
      setAttributes({ Index: page });
    },
    pageSizeOptions: Attributes.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };
  const onFilter = (values) => {
    const queryString = '?' + new URLSearchParams(values).toString();
    navigate(queryString);
    setAttributes({
      ...values,
      Index: 1,
      Size: 10
    });
  };

  const _demandedExport = () => {
    notify("info");
    orderInsuranceReportExport(Attributes)
      .then((res) => {
        downloadFile(res)
        notify("success");
      })
      .catch(() => {
        notify("error");
      });
  };
   
  return (
    <>
      <Collapse>
        <Panel header="Filtreler">
          <Filters initialValues={initialValues} onSubmit={onFilter} />
        </Panel>
      </Collapse>
      <Row>
        <Col style={{ padding: "24px 24px 24px 0", right: 0, marginLeft: "auto"}}>
          <Button type="primary" shape="round" icon={<DownloadOutlined />}
            onClick={_demandedExport}
          >
            Sipariş Sigortası Raporu Export
          </Button>
        </Col>
      </Row>
      <Table
        loading={status === "pending"}
        dataSource={status === "success" ? data.data : []}
        columns={Columns}
        pagination={pagination}
        rowKey={"orderId"}
      />
    </>
  );
};

export default OrderInsuranceReportTable;
