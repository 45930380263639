import apiService from ".";

const customerWithInfoPath = (path) => `/console/Customer/id${path}`;

export const CustomerDetail = async (customerId) => {
  return apiService.get(
    customerWithInfoPath(`/${customerId}`)
  );
};

export const UpdateCustomerRate = async (customerId, rate) => {
  return apiService.patch(
    "/console/Customer/update-customer-rate",
    {
      id: customerId,
      rate
    }
  );
};

