import { Collapse, Table, Button } from "antd";
import useRequest from "../../Hooks/useRequest";
import { formatPrice } from "../../utils/price";
import Filters from "./Filters";
import { getVariantSerialNumberList } from "../../Service/console";
import usePagination from "../../Hooks/usePagination";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import moment from "moment/moment";
import {formatDate} from "../../utils/date";
import {useEffect, useState} from "react";

const { Panel } = Collapse;
const VariantSerialNumberTable = () => {
  const [params, setParams] = usePagination({
    variantId : 0,
    serialNumber: null,
    isPurchaseItem: false,
    allGetVariantSerialNumber: true
  });

  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(getVariantSerialNumberList, [params]);
  const [initialValues, setInitialValues] = useState({});

  const { search } = useLocation();
  const query = new URLSearchParams(search)

  const pricePeriods = {
    10: "Günlük",
    20: "Haftalık",
    30: "Aylık",
    40: "3 Aylık",
    50: "6 Aylık",
    60: "12 Aylık",
  };

  const Columns = [
    {
      key: "id",
      title: "Id",
      dataIndex: "id",
    },
    {
      key: "variantId",
      title: "Variant Id",
      dataIndex: "variantId",
    },
    {
      key: "productName",
      title: "Adı",
      dataIndex: "productName",
    },
    {
      key: "serialNumber",
      title: "Seri Numarası",
      dataIndex: "serialNumber",
    },
  ]; 

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setParams({ Size: pageSize });
    },
    onChange: (page) => {
      setParams({ Index: page });
    },
    pageSizeOptions: params.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };

  const navigate = useNavigate();
  const onFilter = (values) => {
    const queryString = '?' + new URLSearchParams(values).toString();
    navigate(queryString);
    const variantId = values.variantId
    delete values.variantId;
    setParams({
      ...values,
      Index: 1,
      Size: 10,
      ...(variantId && {variantId: parseInt(variantId)})
    });
  };


  useEffect(() => {
    const data = {};
    for (const [key, value] of Object.entries(params)) {
      if(String(query?.get(key)) !== "undefined" && String(query?.get(key)) !== "null"){
        data[key] = query?.get(key)
      }
    }
    delete data.variantId
    setInitialValues({
      ...data,
      allGetVariantSerialNumber : data.allGetVariantSerialNumber ? data.allGetVariantSerialNumber === "true" : true,
      //...(data.variantId && {variantId: parseInt(data.variantId)})
    })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setParams({
        ...params,
        ...initialValues,
      })
    },400)
  }, [initialValues]);

  return (
    <>
      <Collapse>
        <Panel header="Import & Export">
          <Filters initialValues={initialValues} onSubmit={onFilter} />
        </Panel>
      </Collapse>
      <Table
        loading={status === "pending"}
        dataSource={status === "success" ? data.data : []}
        columns={Columns}
        pagination={pagination}
        rowKey={"serialNumber"}
      />
    </>
  );
};

export default VariantSerialNumberTable;
