import SuggestedProductReportTable from "../../Tables/suggested-product-report"


const SuggestedProductReport = () => {

  return (
    <div>
      <SuggestedProductReportTable />
    </div>
  )
}

export default SuggestedProductReport
