import { Button, Collapse, Row, Col, Table } from "antd";
import { DownloadOutlined } from "@ant-design/icons/lib/icons";
import useRequest from "../../Hooks/useRequest";
import Filters from "./Filters";
import {useLocation, useNavigate} from "react-router-dom";
import {purchasePriceReport, purchasePriceReportExport} from "../../Service/report";
import usePagination from "../../Hooks/usePagination";
import { formatDate } from "../../utils/date";
import moment from "moment";
import {useEffect, useState} from "react";
import {formatPrice} from "../../utils/price";
import {notify} from "../../utils/notify";
import {downloadFile} from "../../Service";

const { Panel } = Collapse;
const PurchasedPriceReportTable = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const [initialValues, setInitialValues] = useState({});
  const navigate = useNavigate();
  const [Attributes, setAttributes] = usePagination({
    MinOrderCreatedDate: "",
    MaxOrderCreatedDate: "",
  });


  useEffect(() => {
    const data = {};
    for (const [key, value] of Object.entries(Attributes)) {
      if(String(query?.get(key)) !== "undefined" && String(query?.get(key)) !== "null"){
        data[key] = query?.get(key)
      }
    }

    setInitialValues({
      ...data,
      MinOrderCreatedDate: data.MinOrderCreatedDate ? moment(data.MinOrderCreatedDate,"YYYY-MM-DD") : undefined,
      MaxOrderCreatedDate: data.MaxOrderCreatedDate ? moment(data.MaxOrderCreatedDate,"YYYY-MM-DD") : undefined
    })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setAttributes({
        ...Attributes,
        ...initialValues,
        MinOrderCreatedDate: initialValues.MinOrderCreatedDate ? formatDate(initialValues.MinOrderCreatedDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
        MaxOrderCreatedDate: initialValues.MaxOrderCreatedDate ? formatDate(initialValues.MaxOrderCreatedDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined
      })
    },400)
  }, [initialValues]);


  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(purchasePriceReport, [Attributes]);


  const Columns = [
    {
      key: "productId",
      title: "Cihaz Id",
      dataIndex: "productId",
      width: 70,
    },
    {
      key: "productName",
      title: "Cihaz Adı",
      dataIndex: "productName",
      width: 70,
    },
    {
      key: "serialNumber",
      title: "Seri No",
      dataIndex: "serialNumber",
      width: 70,
    },
    {
      key: "providerType",
      title: "Ürün kaynağı",
      dataIndex: "providerType",
    },
    {
      key: "countRentalByWeekly",
      title: "Haftalık Kiralama",
      dataIndex: "countRentalByWeekly",
      width: 70,
    },
    {
      key: "countRentalByMonthly",
      title: "Aylık Kiralama",
      dataIndex: "countRentalByMonthly",
      width: 70,
    },
    {
      key: "countRentalBy3Monthly",
      title: "3 Aylık Kiralama",
      dataIndex: "countRentalBy3Monthly",
      width: 70,
    },
    {
      key: "totalRevenueByWeekly",
      title: "Haftalık Kiralama Geliri",
      dataIndex: "totalRevenueByWeekly",
      width: 70,
      render: (val) => formatPrice(val),
    },
    {
      key: "totalRevenueByMonthly",
      title: "Aylık Kiralama Geliri",
      dataIndex: "totalRevenueByMonthly",
      width: 70,
      render: (val) => formatPrice(val),
    },
    {
      key: "totalRevenueBy3Monthly",
      title: "3 Aylık Kiralama Geliri",
      dataIndex: "totalRevenueBy3Monthly",
      width: 70,
      render: (val) => formatPrice(val),
    },
    {
      key: "countByRepeatOrder",
      title: "Toplam Uzatma Siparişi",
      dataIndex: "countByRepeatOrder",
      width: 70,
    },
    {
      key: "totalRevenueInsurance",
      title: "Toplam Güvence Geliri",
      dataIndex: "totalRevenueInsurance",
      width: 70,
      render: (val) => formatPrice(val),
    },
    {
      key: "totalRentalDay",
      title: "Toplam Kirada Geçirdiği Gün",
      dataIndex: "totalRentalDay",
      width: 70,
    },
    {
      key: "firstPrice",
      title: "Demirbaşa Giriş Fiyatı",
      dataIndex: "firstPrice",
      width: 70,
      render: (val) => formatPrice(val),
    },
    {
      key: "assetCreatedDate",
      title: "Demirbaşa Giriş Tarihi",
      dataIndex: "assetCreatedDate",
      width: 70,
      render: (date) => formatDate(date),
    },
    {
      key: "orderCreatedDate",
      title: "Demirbaştan Çıkış Tarihi",
      dataIndex: "orderCreatedDate",
      width: 70,
      render: (date) => formatDate(date),
    },
    {
      key: "orderPrice",
      title: "Demirbaştan Çıkış Fiyatı",
      dataIndex: "orderPrice",
      width: 70,
      render: (val) => formatPrice(val),
    },
    {
      key: "currentPrice",
      title: "Demirbaştan Çıktığı Gün Sıfır Fiyatı",
      dataIndex: "currentPrice",
      width: 70,
      render: (val) => formatPrice(val),
    },
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setAttributes({ Size: pageSize });
    },
    onChange: (page) => {
      setAttributes({ Index: page });
    },
    pageSizeOptions: Attributes.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };
  const onFilter = (values) => {
    const queryString = '?' + new URLSearchParams(values).toString();
    navigate(queryString);
    setAttributes({
      ...values,
      Index: 1,
      Size: 10
    });
  };

  const _demandedExport = () => {
    notify("info");
    purchasePriceReportExport(Attributes)
      .then((res) => {
        downloadFile(res)
        notify("success");
      })
      .catch(() => {
        notify("error");
      });
  };

  return (
    <>
      <Collapse>
        <Panel header="Filtreler">
          <Filters initialValues={initialValues} onSubmit={onFilter} />
        </Panel>
      </Collapse>
      <Row>
        <Col style={{ padding: "24px 24px 24px 0", right: 0, marginLeft: "auto"}}>
          <Button type="primary" shape="round" icon={<DownloadOutlined />}
            onClick={_demandedExport}
          >
            Çıktı Al
          </Button>
        </Col>
      </Row>
      <Table
        loading={status === "pending"}
        dataSource={status === "success" ? data.data : []}
        columns={Columns}
        pagination={pagination}
        rowKey={"id"}
      />
    </>
  );
};

export default PurchasedPriceReportTable;
