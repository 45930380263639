import { Form, Button, Row, Col, DatePicker } from 'antd'
import React from 'react'
import { formatDate } from "../../utils/date"; 

function Filters({ onSubmit,initialValues }) {
  const onFinish = (value) => {
    onSubmit({
      ...value,
      MinCreatedAt: value.MinCreatedAt ? formatDate(value.MinCreatedAt,false,"") : undefined,
      MaxCreatedAt: value.MaxCreatedAt ? formatDate(value.MaxCreatedAt,false,"") : undefined,
    })
  }
  return (
    <Form
      labelCol={{ span:7 }}
      wrapperCol={{ span: 14 }}
      initialValues={initialValues}
      layout="horizontal"
      onFinish={onFinish}
      className="filters"
    >
      <Row>
        <Col md={12}>
          <Form.Item label="Min. Sipariş Oluşturma" name="MinCreatedAt">
            <DatePicker />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item label="Max. Sipariş Oluşturma" name="MaxCreatedAt">
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Filtrele
        </Button>
      </Form.Item>
    </Form>
  )
}


export default Filters
