import { Button, Collapse, Table, Tag } from "antd";
import useRequest from "../../Hooks/useRequest";
import Filters from "./Filters";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import { formatDate } from "../../utils/date"; 
import {
  customerWithdDetails,
} from "../../Service/console";
import usePagination from "../../Hooks/usePagination";
import {useEffect, useState} from "react";
import moment from "moment/moment";

const { Panel } = Collapse;
const CustomersTable = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const [initialValues, setInitialValues] = useState({});
  const navigate = useNavigate();
  const [Attributes, setAttributes] = usePagination({
    Id: undefined,
    FirstName: "",
    LastName: "",
    Email: "",
    StartDate: "",
    EndDate: "",
    RoleIds: undefined,
    SegmentIds: undefined,
  });

  useEffect(() => {
    const data = {};
    for (const [key, value] of Object.entries(Attributes)) {
      if(String(query?.get(key)) !== "undefined" && String(query?.get(key)) !== "null"){
        data[key] = query?.get(key)
      }
    }

    setInitialValues({
      ...data,
      StartDate: data.StartDate ? moment(data.StartDate,"YYYY-MM-DD") : undefined,
      EndDate: data.EndDate ? moment(data.EndDate,"YYYY-MM-DD") : undefined
    })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setAttributes({
        ...Attributes,
        ...initialValues,
        StartDate: initialValues.StartDate ? formatDate(initialValues.StartDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
        EndDate: initialValues.EndDate ? formatDate(initialValues.EndDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined
      })
    },400)
  }, [initialValues]);
  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(customerWithdDetails, [Attributes]);
  const Columns = [
    {
      key: "id",
      title: "id",
      dataIndex: "id",
      width: 70
    },
    {
      key: "firstName",
      title: "İsim",
      dataIndex: "firstName",
      width: 100
    },
    {
      key: "lastName",
      title: "Soyisim",
      dataIndex: "lastName",
      width: 100
    },
    {
      key: "identificationNumber",
      title: "TC Kimlik",
      dataIndex: "identificationNumber",
      width: 150
    },
    {
      key: "dateOfBirth",
      title: "Doğum Tarihi",
      dataIndex: "dateOfBirth",
      render: (date) => formatDate(date),
      width: 150
    },
    {
      key: "isDeleted",
      title: "Silindi",
      dataIndex: "isDeleted",
      render: (isDeleted) => isDeleted && <Tag color={"volcano"}>Silindi</Tag>,
      width: 100
    },
    {
      key: "isVerified",
      title: "Doğrulanma",
      dataIndex: "isVerified",
      render: (isVerified) => (
        <Tag color={isVerified ? "geekblue" : "volcano"}>
          {isVerified ? "Doğrulandı" : "Doğrulanmadı"}
        </Tag>
      ),
      width: 150
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      width: 250
    },
    {
      key: "phone",
      title: "Tel no",
      dataIndex: "phone",
      width: 150
    },
    {
      key: "roles",
      title: "Rol",
      dataIndex: "roles",
    },
    {
      key: "isActive",
      title: "Durum",
      dataIndex: "isActive",
      render: (isActive) => (
        <Tag color={isActive ? "geekblue" : "volcano"}>
          {isActive ? "Aktif" : "Pasif"}
        </Tag>
      ),
    },

    {
      key: "lastLoginDateAt",
      title: "En son giriş",
      dataIndex: "lastLoginDateAt",
      render: (date) => formatDate(date,true),
    },
    {
      title: "Detay",
      dataIndex: "",
      fixed: "right",
      key: "x",
      render: (_, Record) => (
        <NavLink to={`/Customer/${Record.id}`}>
          <Button type="primary">Detay</Button>
        </NavLink>
      ),
    },
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setAttributes({ Size: pageSize });
    },
    onChange: (page) => {
      setAttributes({ Index: page });
    },
    pageSizeOptions: Attributes.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };
  const onFilter = (values) => {
    const queryString = '?' + new URLSearchParams(values).toString();
    navigate(queryString);
    setAttributes({
      ...values,
    });
  };
  return (
    <>
      <Collapse>
        <Panel header="Filtreler">
          <Filters initialValues={initialValues} onSubmit={onFilter} />
        </Panel>
      </Collapse>
      <Table
        loading={status === "pending"}
        dataSource={status === "success" ? data.data : []}
        columns={Columns}
        pagination={pagination}
        rowKey={"id"}
        scroll={{ x: 1700 }}
      />
    </>
  );
};

export default CustomersTable;
