import { Button, Collapse, Row, Col, Table, Tag } from "antd";
import { DownloadOutlined } from "@ant-design/icons/lib/icons";
import useRequest from "../../Hooks/useRequest";
import Filters from "./Filters";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {purchaseOfferEvaluationReport, purchaseOfferEvaluationReportExport} from "../../Service/report";
import usePagination from "../../Hooks/usePagination";
import { notify } from "../../utils/notify";
import { formatDate } from "../../utils/date";
import moment from "moment";
import {useEffect, useState} from "react";
import {formatPrice} from "../../utils/price";
import {downloadFile} from "../../Service";

const { Panel } = Collapse;
const PurchaseOfferEvaluationReportTable = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const [initialValues, setInitialValues] = useState({});
  const navigate = useNavigate();
  const [Attributes, setAttributes] = usePagination({
    MaxCreatedDate: "",
    MinCreatedDate: "",
  });


  useEffect(() => {
    const data = {};
    for (const [key, value] of Object.entries(Attributes)) {
      if(String(query?.get(key)) !== "undefined" && String(query?.get(key)) !== "null"){
        data[key] = query?.get(key)
      }
    }

    setInitialValues({
      ...data,
      MaxCreatedDate: data.MaxCreatedDate ? moment(data.MaxCreatedDate,"YYYY-MM-DD") : undefined,
      MinCreatedDate: data.MinCreatedDate ? moment(data.MinCreatedDate,"YYYY-MM-DD") : undefined
    })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setAttributes({
        ...Attributes,
        ...initialValues,
        MaxCreatedDate: initialValues.MaxCreatedDate ? formatDate(initialValues.MaxCreatedDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
        MinCreatedDate: initialValues.MinCreatedDate ? formatDate(initialValues.MinCreatedDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined
      })
    },400)
  }, [initialValues]);


  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(purchaseOfferEvaluationReport, [Attributes]);


  const Columns = [
    {
      key: "productId",
      title: "Cihaz Id",
      dataIndex: "productId",
      width: 70,
    },
    {
      key: "productName",
      title: "Cihaz Adı",
      dataIndex: "productName",
      width: 70,
    },
    {
      key: "serialNumber",
      title: "Seri No",
      dataIndex: "serialNumber",
      width: 70,
    },
    {
      key: "totalRevenue",
      title: "Toplam Kira Geliri",
      dataIndex: "totalRevenue",
      width: 70,
      render: (val) => formatPrice(val),
    },
    {
      key: "totalRentalDay",
      title: "Toplam Kirada Geçirdiği Gün",
      dataIndex: "totalRentalDay",
      width: 70,
    },
    {
      key: "totalInsuranceRevenue",
      title: "Toplam Güvence Geliri",
      dataIndex: "totalInsuranceRevenue",
      width: 70,
      render: (val) => formatPrice(val),
    },
    {
      key: "firstPrice",
      title: "Demirbaşa Giriş Fiyatı",
      dataIndex: "firstPrice",
      width: 70,
      render: (val) => formatPrice(val),
    },
    {
      key: "assetCreatedDate",
      title: "Demirbaşa Giriş Tarihi",
      dataIndex: "assetCreatedDate",
      width: 70,
      render: (date) => formatDate(date),
    },
    {
      key: "purchasePrice",
      title: "Teklif Fiyatı",
      dataIndex: "purchasePrice",
      width: 70,
      render: (val) => formatPrice(val),
    },
    {
      key: "offerEvaluationDate",
      title: "Teklif İstenen Tarih",
      dataIndex: "offerEvaluationDate",
      width: 70,
      render: (date) => formatDate(date),
    },
    {
      key: "currentOrderPriceType",
      title: "Teklif Alan Kullanıcının Kiralama Süresi",
      dataIndex: "currentOrderPriceType",
      width: 70,
    },
    {
      key: "currentOrderPrice",
      title: "Teklif Alan Kullanıcının Ödediği Fiyat",
      dataIndex: "currentOrderPrice",
      width: 70,
      render: (val) => formatPrice(val),
    },
    {
      key: "offerEvaluationMessage",
      title: "Anket Cevapları",
      dataIndex: "offerEvaluationMessage",
      width: 70,
    },
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setAttributes({ Size: pageSize });
    },
    onChange: (page) => {
      setAttributes({ Index: page });
    },
    pageSizeOptions: Attributes.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };
  const onFilter = (values) => {
    const queryString = '?' + new URLSearchParams(values).toString();
    navigate(queryString);
    setAttributes({
      ...values,
      Index: 1,
      Size: 10
    });
  };

  const _demandedExport = () => {
    notify("info");
    purchaseOfferEvaluationReportExport(Attributes)
      .then((res) => {
        downloadFile(res)
        notify("success");
      })
      .catch(() => {
        notify("error");
      });
  };

  return (
    <>
      <Collapse>
        <Panel header="Filtreler">
          <Filters initialValues={initialValues} onSubmit={onFilter} />
        </Panel>
      </Collapse>
      <Row>
        <Col style={{ padding: "24px 24px 24px 0", right: 0, marginLeft: "auto"}}>
          <Button type="primary" shape="round" icon={<DownloadOutlined />}
            onClick={_demandedExport}
          >
            Çıktı Al
          </Button>
        </Col>
      </Row>
      <Table
        loading={status === "pending"}
        dataSource={status === "success" ? data.data : []}
        columns={Columns}
        pagination={pagination}
        rowKey={"id"}
      />
    </>
  );
};

export default PurchaseOfferEvaluationReportTable;
