import { Space, Skeleton } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import CustomerCard from "../../../Components/Cards/CustomerCard";
import useRequest from "../../../Hooks/useRequest";
import { CustomerDetail as CustomerDetailService } from "../../../Service/customer";

function CustomerDetail() {
  const { customerId } = useParams();
  const {
    data = {},
    status,
    update,
  } = useRequest(CustomerDetailService, [customerId]);
  return (
    <Skeleton paragraph={{ rows: 6 }} loading={status === "pending"}>
      <Space direction="vertical">
        <CustomerCard {...data} updateOrder={() => update()}/>
      </Space>
    </Skeleton>
  );
}

export default CustomerDetail;
