import {Button, Modal, Table} from "antd";
import React, {useContext} from "react";
import {formatDate} from "../utils/date";
import {OrderContext} from "../Context/OrderContex";

function OrderDetailModal({ visible, onCancel, data }) {

    const { OrderStatus, PaymentStatus, currentOrderPage, setCurrentOrderPage } =
        useContext(OrderContext);

    const columns = [
        {
            title: 'Görsel',
            dataIndex: 'img',
            key: 'img',
        },
        {
            title: 'Başlık',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Item Id',
            dataIndex: 'id',
            key: 'id',
        },
    ];

    return (
        <Modal
            title="Sipariş Özeti"
            visible={visible}
            footer={null}
            onCancel={onCancel}
            destroyOnClose

        >
            <div id="orderDetailModal">
                <h1 class="title">{data.orderNumber}</h1>
                <ul class="orderSummary">
                    <li>
                        <strong>Sipariş Tarihi</strong>
                        <span>{formatDate(data.date)}</span>
                    </li>
                    <li>
                        <strong>Sipariş Durumu</strong>
                        <span>{OrderStatus.data.find((Orderstat) => Orderstat.id === data.orderStatus)?.value}</span>
                    </li>
                    <li>
                        <strong>Customer Id</strong>
                        <span>{data?.customerId}</span>
                    </li>
                </ul>

                <Table
                    columns={columns}
                    pagination={false}
                    dataSource={data?.items?.map(item => ({
                        key: item.id,
                        id: item.id,
                        productName: item.productName,
                        img: <img alt={item.productName} src={item.pictureUrl}
                              style={{width: 30, height: 30, objectFit: "cover"}}/>
                    }))}
                    size="small"
                />
                <Button type="primary" onClick={() => window.open(
                    `/Order/${data.orderNumber}?isPurchasedOrder=${data.isPurchasedOrder}`,
                    '_blank' // <- This is what makes it open in a new window.
                )}>
                    Siparişe Git
                </Button>
            </div>
        </Modal>
    );
}

export default OrderDetailModal;
