import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list';
import usePagination from "../../Hooks/usePagination";
import React, { useEffect, useState} from "react";
import {formatDate} from "../../utils/date";
import useRequest from "../../Hooks/useRequest";
import {OrderDetail as OrderDetailService, Orders} from "../../Service/order";
import moment from "moment";
import {Collapse, Spin} from 'antd';
import OrderDetailModal from "../../Modals/OrderDetailModal";
import Filters from "../../Tables/OrderCalendar/Filters";
const { Panel } = Collapse;

const OrderCalendar = () => {
    const currentDate = moment();

    const weekStart = currentDate.clone().startOf('isoWeek');
    const weekEnd = currentDate.clone().endOf('isoWeek');

    const [calendarData, setCalendarData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isVisibleOrderDetailModal, setIsVisibleOrderDetailModal] = useState(false);
    const [orderDetailData, setOrderDetailData] = useState({});
    const [dates, setDates] = useState({
        start : weekStart.format(),
        end : weekEnd.format(),
    });

    const [params1, setParams1] = usePagination({
        MinStartDate: weekStart.format(),
        MaxStartDate: weekEnd.format(),
        Index: 1,
        Size: 1000,
        paymentStatuses : 1
    });

    const [params2, setParams2] = usePagination({
        MinEndDate: weekStart.format(),
        MaxEndDate: weekEnd.format(),
        Index: 1,
        Size: 1000,
        paymentStatuses : 1
    });

    const { data:data1 = {data:[]}, status:status1, message:message1, update:update1 } = useRequest(Orders, [params1]);
    const { data:data2 = {data:[]}, status:status2, message:message2, update:update2 } = useRequest(Orders, [params2]);

    useEffect(() => {
        const refactorData1 = data1?.data?.length >0 ? data1?.data?.map(item => {
            return {
                title : `${item.orderNumber}${item.cityId !== 34 ? ' - SD' : ''}`,
                orderNumber : item.orderNumber,
                date: formatDate(item?.orderItemResponses[0]?.starDate,false,"YYYY-MM-DD"),
                eventType: "GÖNDER",
            }
        }) : []
        const refactorData2 = data2?.data?.length >0 ? data2?.data?.map(item => {
            return {
                title : `${item.orderNumber}${item.cityId !== 34 ? ' - SD' : ''}`,
                orderNumber : item.orderNumber,
                date: formatDate(item?.orderItemResponses[0]?.endDate,false,"YYYY-MM-DD"),
                eventType: "TESLİM",
            }
        }) : []

        setCalendarData([...refactorData1,...refactorData2])
    }, [status1,status2]);

    useEffect(() => {
        setIsLoading(status1 === "pending")
    }, [status1]);

    useEffect(() => {
        //console.log(calendarData)
    }, [calendarData]);

    useEffect(() => {
        if(dates.start && dates.end){
            setParams1({
                ...params1,
                MinStartDate: dates.start,
                MaxStartDate: dates.end,
            })
            setParams2({
                ...params2,
                MinEndDate: dates.start,
                MaxEndDate: dates.end,
            })
        }
    }, [dates]);

    const onFilter = (values) => {
        setParams1({
            ...params1,
            ...values,
        });
        setParams2({
            ...params2,
            ...values,
        });
    };

    async function getOrderDetail(orderNumber) {
        setIsLoading(true)
        await OrderDetailService(orderNumber).then(resp => {
            setOrderDetailData(resp)
            setIsVisibleOrderDetailModal(true)
        })
        setIsLoading(false)
    }

    function renderEventContent(eventInfo) {
        return (
            <div className="eventDetail">
                <strong className="eventName">{eventInfo.event.title}</strong>
                <b className={`eventType ${eventInfo.event.extendedProps.eventType === "GÖNDER" ? "success" : "warning"}`}>{eventInfo.event.extendedProps.eventType}</b>
            </div>
        )
    }

    return (
        <>
            <div className="collapseWrapper">
                <Collapse>
                    <Panel header="Filtreler">
                        <Filters onSubmit={onFilter}/>
                    </Panel>
                </Collapse>
            </div>
            <div className="fullCalendar">
                <OrderDetailModal
                    visible={isVisibleOrderDetailModal}
                    onCancel={(e) => setIsVisibleOrderDetailModal(false)}
                    data={orderDetailData}
                />
                <div className={`isLoading ${!isLoading ? "hidden" : ""}`}><Spin tip="Loading" size="large"/></div>
                <FullCalendar
                    plugins={[dayGridPlugin, listPlugin]}
                    initialView="dayGridWeek"
                    firstDay={1}
                    locale="tr"
                    editable
                    height="auto"
                    isLoading={true}
                    eventOrder="eventType"
                    eventContent={renderEventContent}
                    datesSet={(e) => {
                        const startDate = new Date(e.start);
                        startDate.setDate(startDate.getDate() - 1);
                        setDates({
                            start: formatDate(startDate, false, "YYYY-MM-DDTHH:mm:ss"),
                            end: formatDate(e.end, false, "YYYY-MM-DDTHH:mm:ss"),
                        })
                    }}
                    eventClick={(e) => {
                        getOrderDetail(e.event.extendedProps.orderNumber)
                    }}
                    events={calendarData}
                    headerToolbar={{
                        left: 'title',
                        right: 'prev,today,next,dayGridMonth,dayGridWeek',
                    }}
                    dayHeaderFormat={{ weekday: 'long', month: 'long', day: 'numeric', omitCommas: true }}
                    dayHeaderContent={(args) => {
                        //return moment(args.date).format('ddd Do')
                        return args.view.type === "dayGridWeek" ? args.text : args.text.split(" ")[args.text.split(" ").length - 1]
                    }}
                />
            </div>
        </>
    )
}

export default OrderCalendar
