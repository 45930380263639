import { Form, Input, DatePicker, Button } from 'antd'
import React from 'react'
import { formatDate } from "../../utils/date"; 

function Filters({ onSubmit,initialValues }) {
  const onFinish = (value) => {
    onSubmit({
      ...value,
      StartDate: value.StartDate ? formatDate(value.StartDate,false,"") : undefined,
      EndDate: value.EndDate ? formatDate(value.EndDate,false,"") : undefined
    })
  }
  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      initialValues={initialValues}
      layout="horizontal"
      onFinish={onFinish}
    >
       <Form.Item label="Id" name="Id">
        <Input />
      </Form.Item>
      <Form.Item label="İsim" name="FirstName">
        <Input />
      </Form.Item>
      <Form.Item label="Soyisim" name="LastName">
        <Input />
      </Form.Item>
      <Form.Item label="Email" name="Email">
        <Input />
      </Form.Item>
      <Form.Item label="Başlangıç tarihi" name="StartDate">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Bitiş tarihi" name="EndDate">
        <DatePicker />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Filtrele
        </Button>
      </Form.Item>
    </Form>
  )
}


export default Filters
