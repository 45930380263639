import { Descriptions } from 'antd'
import React from 'react'

function BillingAddressCard(billingProps) {
  const {
    fullName,
    email,
    phone,
    title,
    country,
    city,
    county,
    streetAddress,
    type,
    corporateName,
    taxNumber,
    taxOffice
  } = billingProps;
  const list = [
    {
      source: "fullName",
      title: 'İsim Soyisim',
      value: fullName,
    },
    {
      source: "email",
      title: 'E-mail',
      value: email
    },
    {
      source: "phone",
      title: 'Telefon numarası',
      value: phone,
    },
    {
      source: "title",
      title: 'Başlık',
      value: title,
    },
    {
      source: "country",
      title: 'Ülke',
      value: country,

    },
    {
      source: "city",
      title: 'Şehir',
      value: city
    },
    {
      source: "county",
      title: 'İlçe',
      value: county
    },
    {
      source: "streetAddress",
      title: 'Adres',
      value: streetAddress
    },
    {
      source: "type",
      title: 'Adres Tipi',
      value: type === 1 ? 'Bireysel' : 'Kurumsal'
    }
  ]

  if(type === 2){
    list.push(
        {
          source: "corporateName",
          title: 'Firma Adı',
          value: corporateName
        },
        {
          source: "taxNumber",
          title: 'Vergi Numarası',
          value: taxNumber
        },
        {
          source: "taxOffice",
          title: 'Vergi Dairesi',
          value: taxOffice
        }
    )
  }
  return (
    <div className="order-detail-description-card">
    <Descriptions
      title="Fatura Adresi"
      bordered
      column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
    >
      {list.map(item => 
      <Descriptions.Item key={item.source} label={item.title}>{item.value}</Descriptions.Item>
      )}
    </Descriptions>
    </div>
  )
}

export default BillingAddressCard
