import { useState } from "react"

function usePagination(additionalAttribute = {}) {
  const [Attributes, _setAttributes] = useState({
    Index: 1,
    Size: 10,
    INDEX_PAGE_SIZE_OPTIONS: [5, 10, 20, 30, 50, 100],
    ...additionalAttribute
  })
  const setAttributes = (_Attributes) => {
    _setAttributes(prevAttributes => ({
      ...prevAttributes,
      ..._Attributes
    }))
  }
  return (
    [
      Attributes,
      setAttributes
    ]
  )
}

export default usePagination
