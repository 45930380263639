import React from "react";
import styles from "./Title.module.css";
import { Col } from "antd";

const Title = (props) => {
  const { label, colspan } = props;
  return (
    <Col span={colspan}>
      <h1 className={styles.title}>{label}</h1>
    </Col>
  );
};

export default Title;
