import { Button, Collapse, Row, Col } from "antd";
import { DownloadOutlined } from "@ant-design/icons/lib/icons"; 
import Filters from "./Filters"; 
import {
  InvoiceAndWaybillExport
} from "../../Service/console";
import { downloadFile } from "../../Service";
import usePagination from "../../Hooks/usePagination";
import { useEffect, useState } from "react";
import {formatDate} from "../../utils/date"
import { notify } from "../../utils/notify";

const { Panel } = Collapse;
const InvoiceAndWaybillReportTable = () => {
  const [Attributes, setAttributes] = useState({
    request: undefined,
  });
  
  const onFilter = (values) => {
    setAttributes({
      //...values,
      request: values.StartDate ? formatDate(values.StartDate,false,"YYYY-MM-DD") : undefined,
    })
  };

  useEffect(() => {
    if(Attributes.request !== undefined){
      exportData()
    }
  },[Attributes])

  const exportData = () => {
    notify("info");
    InvoiceAndWaybillExport(Attributes)
      .then((res) => {
        downloadFile(res)
        notify("success");
      })
      .catch((err) => {
        notify("error");
      });
  }; 

  return (
    <>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Filtreler" key="1">
          <Filters onSubmit={onFilter} />
        </Panel>
      </Collapse> 
    </>
  );
};

export default InvoiceAndWaybillReportTable;
