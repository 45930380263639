import {Button, Collapse, Modal, Table} from "antd";
import useRequest from "../../Hooks/useRequest";
import Filters from "./Filters";
import {addBox, deleteBox, getBoxList} from "../../Service/console";
import usePagination from "../../Hooks/usePagination";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import AddBoxModal from "../../Modals/AddBoxModal";
import {notify} from "../../utils/notify";

const { Panel } = Collapse;
const ProductBoxesTable = () => {
  const [params, setParams] = usePagination({
    name: null,
    code: null
  });

  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(getBoxList, [params]);
  const [initialValues, setInitialValues] = useState({});
  const [addBoxModalVisible, setAddBoxModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'))

  const { search } = useLocation();
  const query = new URLSearchParams(search)

  const Columns = [
    {
      key: "id",
      title: "Id",
      dataIndex: "id",
    },
    {
      key: "name",
      title: "Kutu İsmi",
      dataIndex: "name",
    },
    {
      key: "code",
      title: "Kod",
      dataIndex: "code",
    },
    {
      key: "width",
      title: "En",
      dataIndex: "width",
      render: (val) => `${val} cm`,
    },
    {
      key: "size",
      title: "Boy",
      dataIndex: "size",
      render: (val) => `${val} cm`,
    },
    {
      key: "height",
      title: "Yükseklik",
      dataIndex: "height",
      render: (val) => `${val} cm`,
    },
    {
      key: "desi",
      title: "Desi",
      dataIndex: "desi",
    },
    {
      key: "actions",
      title: "İşlemler",
      render: (_, Record) => {
        return (
            <div>
              <NavLink to={`/product-boxes/${Record.id}`}>
                <Button type="primary">Düzenle</Button>
              </NavLink>
              <Button
                  type="primary"
                  style={{
                    background: "#dc3545",
                    borderColor: "#dc3545",
                    color: "#ffffff",
                    marginLeft : "10px"
                  }}
                  onClick={() => areYouSureDeleted(Record.id,Record.name, Record.code)}
              >
                Sil
              </Button>
            </div>
        );
      },
    },
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setParams({ Size: pageSize });
    },
    onChange: (page) => {
      setParams({ Index: page });
    },
    pageSizeOptions: params.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };

  const navigate = useNavigate();
  const onFilter = (values) => {
    const queryString = '?' + new URLSearchParams(values).toString();
    navigate(queryString);
    setParams({
      ...values,
      Index: 1,
      Size: 10,
    });
  };


  useEffect(() => {
    const data = {};
    for (const [key, value] of Object.entries(params)) {
      if(String(query?.get(key)) !== "undefined" && String(query?.get(key)) !== "null"){
        data[key] = query?.get(key)
      }
    }
    setInitialValues({
      ...data,
    })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setParams({
        ...params,
        ...initialValues,
      })
    },400)
  }, [initialValues]);

  const onFinishAddBox = (values) => {
    //console.log(values)
    notify("info");
    setLoading(true)
    addBox({...values,userId:user.id,desi:0})
        .then((res) => {
          notify("success");
          setLoading(false)
          setAddBoxModalVisible(false)
          update()
        })
        .catch((err) => {
          notify("error");
          setLoading(false)
          setAddBoxModalVisible(false)
        });
  };

  const handleDelete = (boxId) => {
    //console.log(values)
    notify("info");
    setLoading(true)
    deleteBox(boxId,user.id)
        .then((res) => {
          notify("success");
          setLoading(false)
          update()
        })
        .catch((err) => {
          notify("error");
          setLoading(false)
        });
  };


  const { confirm } = Modal;
  const areYouSureDeleted = (boxId,name,code) => {
    confirm({
      title: `"${name} (${code})" isimli kutu silinecek. Onaylıyor musunuz?`,
      // icon: <ExclamationCircleOutlined />,
      okText: 'Onay',
      cancelText: 'İptal',
      onOk() {
        handleDelete(boxId);
      },
    });
  }


  const onCancelAddBox = () => setAddBoxModalVisible(false)


  return (
      <>
        <AddBoxModal visible={addBoxModalVisible} onFinish={onFinishAddBox} onCancel={onCancelAddBox} loading={loading}/>
        <div className="collapseWrapper">
          <Button className="cargoBtn" onClick={setAddBoxModalVisible} type="secondary" htmlType="button">
            Kutu Ekle
          </Button>
          <Collapse>
            <Panel header="Filtrele">
              <Filters initialValues={initialValues} onSubmit={onFilter}/>
            </Panel>
          </Collapse>
        </div>
        <Table
            loading={status === "pending"}
            dataSource={status === "success" ? data.data : []}
            columns={Columns}
            pagination={pagination}
            rowKey={"serialNumber"}
        />
      </>
  );
};

export default ProductBoxesTable;
