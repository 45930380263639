import {Form, Button, Row, Col, Input} from "antd";

function Filters({ onSubmit,initialValues }) {

    const onFinish = (value) => onSubmit({...value,});

    return (
        <Form
            labelCol={{span: 4}}
            wrapperCol={{span: 14}}
            layout="horizontal"
            id="periodicSalesReportFilterForm"
            onFinish={onFinish}
            initialValues={initialValues}
        >
            <Row align="center">
                <Col lg={24} md={12}>
                    <Row>
                        <Col md={12}>
                            <Form.Item label="Kutu İsmi" name="name">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item label="Kutu Kodu" name="code">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item label="Kutu Id" name="id">
                                <Input type="number"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Filtrele
                </Button>
            </Form.Item>
        </Form>
    );
}

export default Filters;
