import React from "react";
import CurrentRentalReportTable from "../../Tables/current-rental-report";

function CurrentRentalReport() {
  return (
    <div>
      <CurrentRentalReportTable />
    </div>
  );
}

export default CurrentRentalReport;
