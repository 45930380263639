import { HomeOutlined, UnorderedListOutlined, UserOutlined ,DatabaseOutlined,AppstoreAddOutlined,PrinterOutlined } from "@ant-design/icons/lib/icons";
import Customer from "../Pages/Customer";
import Products from "../Pages/Products";
import ProductDetail from "../Pages/Products/Id";
import InvoiceAndWaybill from "../Pages/invoice-and-waybill";
import Home from "../Pages/Home";
import Order from "../Pages/Order";
import OrdeDetail from '../Pages/Order/Id'
import CustomerDetail from '../Pages/Customer/Id'
import Data from "../Pages/Data";
import PeriodicSalesReport from "../Pages/periodic-sales-report"
import CustomerOrderReport from "../Pages/customer-order-report"
import OrderStatisticReport from "../Pages/order-statistic-report"
import SuggestedProductReport from "../Pages/suggested-product-report"
import OrderInsuranceReport from "../Pages/order-insurance-report"
import ProductClickReport from "../Pages/product-click-report"
import DemandedProductReport from "../Pages/demanded-product-report"
import ProductSalesReport from "../Pages/product-sales-report"
import CurrentRentalReport from "../Pages/current-rental-report";
import CurrentRentalReportDetail from "../Pages/current-rental-report/Id";
import PurchaseOfferEvaluationReport from "../Pages/get-purchase-offer-evaluation-report";
import GetShipment from "../Pages/GetShipment";
import Returneds from "../Tables/Returneds";
import PurchasedPriceReport from "../Pages/purchased-price-report";
import VariantSerialNumberDetail from "../Pages/variant-serial-number/Id";
import VariantSerialNumber from "../Pages/variant-serial-number";
import ProductBoxes from "../Pages/product-boxes";
import ProductBoxesDetail from "../Pages/product-boxes/Id";
import SaveCardOrders from "../Tables/SaveCardOrders";
import OrderCalendar from "../Pages/OrderCalendar";

const Routes = [
  {
    path: '/',
    element: <Home />,
    Title: 'Anasayfa',
    id: 'Home',
    index: true,
    NavList: true,
    icon: <HomeOutlined />
  },
  {
    path: '/Order',
    element: <Order />,
    Title: 'Siparişler',
    id: 'Order',
    index: true,
    NavList: true,
    icon: <UnorderedListOutlined />
  },
  {
    path: '/all-orders',
    element: <Order />,
    Title: 'Tüm Siparişler',
    id: 'all-orders',
    parent: 'Order',
    index: false,
    NavList: false,
    icon: <UnorderedListOutlined />
  },
  {
    path: '/order-calendar',
    element: <OrderCalendar />,
    Title: 'Sipariş Takvimi',
    id: 'order-calendar',
    parent: 'Order',
    index: false,
    NavList: false,
    icon: <UnorderedListOutlined />
  },
  {
    path: '/get-shipment',
    element: <GetShipment />,
    Title: 'Bugün Kargolanacaklar',
    id: 'get-shipment',
    parent: 'Order',
    index: false,
    NavList: false,
    icon: <UnorderedListOutlined />
  },
  {
    path: '/get-returned',
    element: <Returneds />,
    Title: 'Bugün Gelecekler',
    id: 'get-returned',
    parent: 'Order',
    index: false,
    NavList: false,
    icon: <UnorderedListOutlined />
  },
  {
    path: '/save-card-orders',
    element: <SaveCardOrders />,
    Title: 'Kart Kaydetme Siparişleri',
    id: 'save-card-orders',
    parent: 'Order',
    index: false,
    NavList: false,
    icon: <UnorderedListOutlined />
  },
  {
    path: '/Order/:orderNumber',
    element: <OrdeDetail />,
    Title: 'Sipariş Detay',
    id: 'OrderDetail',
  },
  {
    path: '/Customer/:customerId',
    element: <CustomerDetail />,
    Title: 'Müşteri Detay',
    id: 'CustomerDetail',
  },
  {
    path: '/Customer',
    element: <Customer />,
    Title: 'Kullanıcılar',
    id: 'Customer',
    index: true,
    NavList: true,
    icon: <UserOutlined />
  },
  {
    path: '/Products',
    element: <Products />,
    Title: 'Ürünler',
    id: 'products',
    index: true,
    NavList: true,
    icon: <AppstoreAddOutlined />
  },
  {
    path: '/Products',
    element: <Products />,
    Title: 'Tüm Ürünler',
    id: 'all-orders',
    parent: 'products',
    index: false,
    NavList: false,
    icon: <AppstoreAddOutlined />
  },
  {
    path: '/variant-serial-number',
    element: <VariantSerialNumber />,
    Title: 'Variant Serial Number',
    id: 'variant-serial-number',
    parent: 'products',
    index: false,
    NavList: false,
    icon: <AppstoreAddOutlined />
  },
  {
    path: '/product-boxes',
    element: <ProductBoxes />,
    Title: 'Ürün Kutuları',
    id: 'product-boxes',
    parent: 'products',
    index: false,
    NavList: false,
    icon: <AppstoreAddOutlined />
  },
  {
    path: '/product-boxes/:boxId',
    element: <ProductBoxesDetail />,
    Title: 'Ürün Kutu Detayı',
    id: 'product-boxes-detail',
  },
  {
    path: '/Products/:productId',
    element: <ProductDetail />,
    Title: 'Ürün Detay',
    id: 'Product Detail',
  },
  {
    path: '/variant-serial-number/:productId',
    element: <VariantSerialNumberDetail />,
    Title: 'Variant Serial Number Detail',
    id: 'variant-serial-number-detail',
  },
  {
    path: '#',
    Title: 'Raporlar',
    id: 'reports',
    index: false,
    NavList: true,
    icon: <PrinterOutlined />
  },
  // {
  //   path: '/Data',
  //   element: <Data />,
  //   Title: 'Data',
  //   NavList: true,
  //   id: 'Data',
  //   icon:<DatabaseOutlined />
  // },
  {
    path: '/periodic-sales-report',
    element: <PeriodicSalesReport />,
    NavList: false,
    id: 'periodic-sales-report',
    parent : 'reports',
    Title: 'Ultimate Rental Data',
  },
  {
    path: '/current-rental-report',
    element: <CurrentRentalReport />,
    NavList: false,
    id: 'current-rental-report',
    parent : 'reports',
    Title: 'Products On Rental',
  },
  {
    path: '/current-rental-report/:productId',
    element: <CurrentRentalReportDetail />,
    Title: 'Güncel Kiralama Raporu Detay',
    id: 'current-rental-report-detail',
  },
  {
    path: '/customer-order-report',
    element: <CustomerOrderReport />,
    NavList: false,
    id: 'customer-order-report',
    parent : 'reports',
    Title: 'Registered Users Info',
  },
  {
    path: '/order-statistic-report',
    element: <OrderStatisticReport />,
    NavList: false,
    id: 'order-statistic-report',
    parent : 'reports',
    Title: 'Total Statistics',
  },
  {
    path: '/suggested-product-report',
    element: <SuggestedProductReport />,
    NavList: false,
    id: 'suggested-product-report',
    parent : 'reports',
    Title: 'Device Recommendations',
  },
  {
    path: '/order-insurance-report',
    element: <OrderInsuranceReport />,
    NavList: false,
    id: 'order-insurance-report',
    parent : 'reports',
    Title: 'Damage Coverage',
  },
  {
    path: '/product-click-report',
    element: <ProductClickReport />,
    NavList: false,
    id: 'product-click-report',
    parent : 'reports',
    Title: 'Product Page Clicks',
  },
  {
    path: '/demanded-product-report',
    element: <DemandedProductReport />,
    NavList: false,
    id: 'demanded-product-report',
    parent : 'reports',
    Title: 'Visitors and Basket Details',
  },
  {
    path: '/product-sales-report',
    element: <ProductSalesReport />,
    NavList: false,
    id: 'product-sales-report',
    parent : 'reports',
    Title: 'Serial No-Based Rentals',
  },
  {
    path: '/invoice-and-waybill',
    element: <InvoiceAndWaybill />,
    NavList: false, 
    id: 'invoice-and-waybill',
    parent : 'reports',
    Title: 'Accounting',
  },
  {
    path: '/purchase-offer-evaluation-report',
    element: <PurchaseOfferEvaluationReport />,
    NavList: false,
    id: 'purchase-offer-evaluation-report',
    parent : 'reports',
    Title: 'Sales Survey',
  },
  {
    path: '/purchase-price-report',
    element: <PurchasedPriceReport />,
    NavList: false,
    id: 'purchase-price-report',
    parent : 'reports',
    Title: 'Sold Products Details',
  },
]

export default Routes;