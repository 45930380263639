import {Button, Space, Input, Form, Modal, Select, InputNumber} from "antd";
import React from "react";
const { Option } = Select;

function AddBoxModal({ visible, onFinish, onCancel, loading }) {

    return (
        <Modal
            title="Kutu Ekle"
            visible={visible}
            footer={null}
            onCancel={onCancel}
            destroyOnClose
        >
                <Form
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 17 }}
                    layout="horizontal"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Kutu İsmi: "
                        name="name"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item
                        label="Kutu Kodu: "
                        name="code"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Code" />
                    </Form.Item>
                    <Form.Item
                        label="En: "
                        name="width"
                        rules={[{ required: true }]}
                    >
                        <Input type="number" placeholder="Width" />
                    </Form.Item>
                    <Form.Item
                        label="Boy: "
                        name="size"
                        rules={[{ required: true }]}
                    >
                        <Input type="number" placeholder="Size" />
                    </Form.Item>
                    <Form.Item
                        label="Yükseklik: "
                        name="height"
                        rules={[{ required: true }]}
                    >
                        <Input type="number" placeholder="Height" />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 4 }}  style={{justifyContent:"flex-end"}}>
                        <Space split style={{justifyContent:"flex-end"}}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Ekle
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
        </Modal>
    );
}

export default AddBoxModal;
