import { createContext, useState } from "react";
import useRequest from "../Hooks/useRequest";
import { orderStatuses, orderItemStatuses, paymentStatuses } from "../Service/common";
export const OrderContext = createContext({
  OrderStatus: {},
  PaymentStatus: {},
  currentOrderPage: 0,
});

export const OrderContextProvider = ({ children }) => {
  const OrderStatus = useRequest(orderStatuses, []);
  const OrderItemStatuses = useRequest(orderItemStatuses, []);
  const PaymentStatus = useRequest(paymentStatuses, []);
  const [currentOrderPage, setCurrentOrderPage] = useState(0);

  const handleCurrentOrderPage = (val) => setCurrentOrderPage(val);

  return (
    <OrderContext.Provider
      value={{
        OrderStatus: {
          ...OrderStatus,
          data: OrderStatus.data || [],
        },
        OrderItemStatuses: {
          ...OrderItemStatuses,
          data: OrderItemStatuses.data || [],
        },
        PaymentStatus: {
          ...PaymentStatus,
          data: PaymentStatus.data || [],
        },
        currentOrderPage,
        setCurrentOrderPage: handleCurrentOrderPage
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
