import OrderStatisticReportTable from "../../Tables/order-statistic-report"


const OrderStatisticReport = () => {

  return (
    <div>
      <OrderStatisticReportTable />
    </div>
  )
}

export default OrderStatisticReport
