import apiService from "."

const ReportPath = (path) => `/console/Report${path}`;

export const periodicSalesReport = async (filters) => {
  return apiService.get(ReportPath('/periodic-sales-report'), {
    params: filters,
  })
} 

export const periodicSalesReportExport = async (filters) => {
  return apiService.get(ReportPath('/periodic-sales-report-export'), {
    responseType: "blob",
    params: filters,
  })
} 

export const customerOrderReport = async (filters) => {
  return apiService.get(ReportPath('/customer-order-report'), {
    params: filters,
  })
} 

export const customerOrderReportExport = async (filters) => {
  return apiService.get(ReportPath('/customer-order-report-export'), {
    responseType: "blob",
    params: filters,
  })
} 

export const orderStatisticReport = async (filters) => {
  return apiService.get(ReportPath('/order-statistics-report'), {
    params: filters,
  })
}

export const orderStatisticReportExport = async (filters) => {
  return apiService.get(ReportPath('/order-statistics-report-export'), {
    responseType: "blob",
    params: filters,
  })
}

export const suggestedProductReport = async (filters) => {
  return apiService.get(ReportPath('/suggested-product-report'), {
    params: filters,
  })
}

export const suggestedProductReportExport = async (filters) => {
  return apiService.get(ReportPath('/suggested-product-report-export'), {
    responseType: "blob",
    params: filters,
  })
}

export const orderInsuranceReport = async (filters) => {
  return apiService.get(ReportPath('/order-insurance-report'), {
    params: filters,
  })
}

export const orderInsuranceReportExport = async (filters) => {
  return apiService.get(ReportPath('/order-insurance-report-export'), {
    responseType: "blob",
    params: filters,
  })
}

export const productClickReport = async (filters) => {
  return apiService.get(ReportPath('/product-click-report'), {
    params: filters,
  })
}

export const productClickReportExport = async (filters) => {
  return apiService.get(ReportPath('/product-click-report-export'), {
    responseType: "blob",
    params: filters,
  })
}

export const demandedProductReport = async (filters) => {
  return apiService.get(ReportPath('/demanded-product-report'), {
    params: filters,
  })
}

export const demandedProductReportExport = async (filters) => {
  return apiService.get(ReportPath('/demanded-product-report-export'), {
    responseType: "blob",
    params: filters,
  })
}

export const productSalesReport = async (filters) => {
  return apiService.get(ReportPath('/product-sales-report'), {
    params: filters,
  })
}

export const productSalesReportExport = async (filters) => {
  return apiService.get(ReportPath('/product-sales-report-export'), {
    responseType: "blob",
    params: filters,
  })
}

export const purchasePriceReportExport = async (filters) => {
  return apiService.get(ReportPath('/get-purchase-price-report-export'), {
    responseType: "blob",
    params: filters,
  })
}

export const purchaseOfferEvaluationReportExport = async (filters) => {
  return apiService.get(ReportPath('/get-purchase-offer-evaluation-report-export'), {
    responseType: "blob",
    params: filters,
  })
}

export const purchaseOfferEvaluationReport = async (filters) => {
  return apiService.get(ReportPath('/get-purchase-offer-evaluation-report'), {
    params: filters,
  })
}

export const purchasePriceReport = async (filters) => {
  return apiService.get(ReportPath('/get-purchase-price-report'), {
    params: filters,
  })
}