import InvoiceAndWaybillReportTable from "../../Tables/invoice-and-waybill-report"

const InvoiceAndWaybill = () => {

  return (
    <div>
    <InvoiceAndWaybillReportTable />
    </div>
  )
}

export default InvoiceAndWaybill
