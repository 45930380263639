import CustomersTable from "../../Tables/Customers"


const Customer = () => {

  return (
    <div>
      <CustomersTable />
    </div>
  )
}

export default Customer
