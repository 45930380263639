import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons/lib/icons";
import {Descriptions, Select, Spin, Button, Space} from "antd";
import React, { useState, useContext } from "react";
import { OrderContext } from "../../Context/OrderContex";
import {sendShipment, updateOrderStatus, updatePaymentStatus, sendDispatch} from "../../Service/order";
import { startInsurance } from "../../Service/console";
import { formatDate } from "../../utils/date"; 
import { notify } from "../../utils/notify";
import CargoModal from "../../Modals/CargoModal";
import WaybillModal from "../../Modals/WaybillModal";

const { Option } = Select;
function OrderCard(OrderProps) {
  const { id, createdAt,totalText,totalCodeDiscountText,currencyCode, orderNumber, orderStatus, paymentStatus, updateOrder, couponCode, totalCodeDiscount, totalDiscountWithoutCode, totalOrderCount,transactions,transaction } =
    OrderProps;
  const { OrderStatus, PaymentStatus } = useContext(OrderContext);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cargoModalVisible, setCargoModalVisible] = useState(false);
  const [waybillModalVisible, setWaybillModalVisible] = useState(false);
  const _updateOrderStatus = (value) => {
    setUpdating(true);
    updateOrderStatus(id, value).then(() => {
      setUpdating(false);
      updateOrder();
    });
  };
  const _updatePaymentStatus = (value) => {
    setUpdating(true);
    updatePaymentStatus(id, value).then(() => {
      setUpdating(false);
      updateOrder();
    });
  };
  const list = [
    {
      source: "id",
      title: "Sipariş id",
      display: id,
    },
    {
      source: "createdAt",
      title: "Oluşturulma Tarihi",
      display: formatDate(createdAt,true,"DD.MM.YYYY HH:mm:ss",false),
    },
    {
      source: "total",
      title: "Toplam Tutar",
      display: `${totalText} ${currencyCode}`,
    },
    {
      source: "orderNumber",
      title: "Sipariş Numarası",
      display: orderNumber,
    },
    {
      source: "orderStatus",
      title: "Sipariş durumu",
      value: orderStatus,
      display: OrderStatus.data.find(
        (Orderstat) => Orderstat.id === orderStatus
      )?.value,
      submit: _updateOrderStatus,
      options: OrderStatus.data,
      editable: true,
    },
    {
      source: "totalOrderCount",
      title: "Toplam Sipariş Sayısı",
      display: totalOrderCount,
    },
    {
      source: "paymentStatus",
      title: "Ödeme durumu",
      value: paymentStatus,
      display: PaymentStatus.data.find(
        (Orderstat) => Orderstat.id === paymentStatus
      )?.value,
      submit: _updatePaymentStatus,
      options: PaymentStatus.data,
      editable: true,
    },
    {
      source: "couponCode",
      title: "İndirim",
      display: couponCode ? "Uygulandı" : "Uygulanmadı",
    },
      {
      source: "couponCode",
      title: "İndirim Kodu",
      display: couponCode ?? "-",
    },
    {
      source: "totalCodeDiscount",
      title: "İndirim Tutarı",
      display: totalCodeDiscount > 0 ? `${totalCodeDiscountText} ${currencyCode}` : "-",
    },
    {
      source: "totalDiscountWithoutCode",
      title: "İndirimsiz Tutar",
      display: totalDiscountWithoutCode > 0 ? totalDiscountWithoutCode : "-",
    },
    {
      source: "installmentCount",
      title: "Taksit Sayısı",
      display: transactions && transactions.length > 0 && transactions[0] && transactions[0]?.installmentCount > 1 ? transactions[0]?.installmentCount + " Taksit" : "Tek Çekim",
    },
    {
      source: "transaction",
      title: "Sanal Pos",
      display: transactions && transactions.length > 0 && transactions[0] && transactions[0]?.gatewayName,
    },
  ];

  const _startInsurance = (orderNumber) => {
    notify("info");
    startInsurance(orderNumber)
      .then(() => {
        notify("success");
      })
      .catch(() => {
        notify("error");
      });
  };

  const onFinish = (values) => {
    notify("info");
    sendShipment({...values,boxIds : Object.values(values.boxIds).filter(item => ![null,undefined].includes(item))})
        .then(() => {
          notify("success");
          setCargoModalVisible(false)
        })
        .catch(() => {
          notify("error");
          setCargoModalVisible(false)
        });
  };

  const onCancel = () => {
    setCargoModalVisible(false)
  };

  const onWaybillFinish = (values) => {
    notify("info");
    sendDispatch(values)
        .then(() => {
          notify("success");
          setWaybillModalVisible(false)
        })
        .catch(() => {
          notify("error");
          setWaybillModalVisible(false)
        });
  };

  const onWaybillCancel = () => {
    setWaybillModalVisible(false)
  };

  return (
    <Spin spinning={updating}>
      <Descriptions className="orderDetailButtons" title="Sipariş Detay" bordered column={{xs: 1, sm: 1, md: 2}} extra={<>
        <Space className="orderDetailButtonsInner" align="baseline">
          {orderStatus !== 13 && (
            <>
              <Button type="success" onClick={setWaybillModalVisible}>İrsaliye İşlemleri</Button>
              <Button type="orange" onClick={setCargoModalVisible}>Kargo İşlemleri</Button>
            </>
          )}
          <Button type="primary" onClick={() => _startInsurance(orderNumber)}>Sigorta Başlat</Button>
        </Space>
      </>}>
        {list.map((item) => (
          <Descriptions.Item key={item.source} label={item.title}>
            <EditableItem
              value={item.value}
              display={item.display}
              options={item.options}
              editable={item.editable}
              submit={item.submit}
            />
          </Descriptions.Item>
        ))}
      </Descriptions>
      <CargoModal visible={cargoModalVisible} onFinish={onFinish} onCancel={onCancel} loading={loading} data={OrderProps}/>
      <WaybillModal visible={waybillModalVisible} onFinish={onWaybillFinish} onCancel={onWaybillCancel} loading={loading} data={OrderProps}/>
    </Spin>
  );
}

const EditableItem = ({ value, submit, display, options = [], editable }) => {
  const [edit, setedit] = useState(false);
  const [selected, setselected] = useState(value);
  return !edit ? (
    <>
      <p>{display}</p>
      {editable && (
        <EditOutlined
          onClick={() => {
            setedit(true);
          }}
        />
      )}
    </>
  ) : (
    <>
      <div>
        <Select
          defaultValue="lucy"
          style={{ width: 120 }}
          onChange={(val) => setselected(val)}
          value={selected}
        >
          {options.map((option) => (
            <Option value={option.id}>{option.value}</Option>
          ))}
        </Select>
      </div>
      <CheckOutlined onClick={() => submit(selected)} />
      <CloseOutlined onClick={() => setedit(false)} />
    </>
  );
};

export default OrderCard;
