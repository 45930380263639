import VariantSerialNumberTable from "../../Tables/variant-serial-number";

const VariantSerialNumber = () => {

  return (
    <div>
      <VariantSerialNumberTable />
    </div>
  )
}

export default VariantSerialNumber
