import {Form, Select, Input, DatePicker, Button, Col, Row} from "antd";
import React, {useContext, useState} from "react";
import {OrderContext} from "../../Context/OrderContex";
import {formatDate} from "../../utils/date";
import {getProductListWithFilter} from "../../Service/console";

const {Option} = Select;

function Filters({onSubmit, initialValues}) {
    const {OrderItemStatuses} = useContext(OrderContext);

    const onFinish = (value) => {
        onSubmit({
            ...value,
            //OrderStatuses: getOrderStatus(value),
            //PaymentStatuses: getPaymentStatus(value),
            StartDate: value.StartDate ? formatDate(value.StartDate, false, "") : undefined,
            EndDate: value.EndDate ? formatDate(value.EndDate, false, "") : undefined
        });
    };
    const [productList,setProductList] = useState([])
    const [isSelectLoading,setIsSelectLoading] = useState(false)

    const onSearch = async (value) => {
        setIsSelectLoading(true)
        await getProductListWithFilter({
            query: value,
            filterOrderByType: 0,
            pageIndex: 1,
            pageSize: 1000,
            brandIds: []
        }).then(resp => {
            setProductList(resp?.data)
            setIsSelectLoading(false)
        }).catch(err => {
            setIsSelectLoading(false)
            return err
        })
    };

    /* const getOrderStatus = (value) => {
      if (value.OrderStatuses) {
        return value.OrderStatuses.find((OrderStatus) => OrderItemStatuses.data.find((Orderstat) => Orderstat.id === parseInt(OrderStatus)).id);
      }
    };

    const getPaymentStatus = (value) => {
      if (value.PaymentStatuses) {
        return value.PaymentStatuses.find((PaymentStatus) => PaymentStatuses.data.find((Paymentstat) => Paymentstat.id === parseInt(PaymentStatus)).id);
      }
    }; */


    return (
        <Form labelCol={{span: 6}}
              wrapperCol={{span: 14}}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={initialValues}
        >
            <div className="filters">
                <Row>
                    <Col md={12}>
                        <Form.Item label="Email" name="BillingAddressEmail">
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Sipariş no" name="OrderNumber">
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Item label="Başl. Tarihi" name="StartDate">
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Bitiş Tarihi" name="EndDate">
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Item label="Sipariş dur:" name="OrderStatuses">
                            <Select style={{width: "100%"}} placeholder="Lütfen Seçin">
                                {OrderItemStatuses.data.map((orderStatus) => (
                                    <Option key={orderStatus.id}>{orderStatus.value}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Ürün:" name="productIds">
                            <Select
                                mode="tags"
                                style={{ width: "100%" }}
                                showSearch
                                loading={isSelectLoading}
                                onSearch={onSearch}
                                placeholder="Lütfen ürün arayın"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }

                            >
                                {productList?.map((list) => (
                                    <Option key={list?.variantDetailResponses[0]?.variantId}>{list?.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Filtrele
                </Button>
            </Form.Item>
        </Form>
    );
}

export default Filters;
