import PurchasedPriceReportTable from "../../Tables/purchased-price-report";


const PurchasedPriceReport = () => {

  return (
    <div>
      <PurchasedPriceReportTable />
    </div>
  )
}

export default PurchasedPriceReport
