import { Button, Space, Input, Form, Modal } from "antd";
import React from "react";

function OrderItemModal({ visible, onFinish, onCancel, item,  loading }) {
  return (
    <Modal
      title="Seri Numarası Güncelle"
      visible={visible}
      footer={null}
      onCancel={onCancel}
      destroyOnClose
    >
      <Form
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={onFinish}
      >

          <Form.Item
              name="id"
              label="Id:"
              rules={[{ required: true, message: "Eksik id" }]}
              initialValue={item?.id}
          >
              <Input placeholder="id" disabled />
          </Form.Item>
          <Form.Item
              name="sku"
              label="SKU:"
              rules={[{ required: true, message: "Eksik sku" }]}
              initialValue={item?.sku}
          >
              <Input placeholder="sku" disabled />
          </Form.Item>
          <Form.Item
              name="serialNumber"
              label="Seri Numarası:"
              rules={[{ required: true, message: "Eksik Seri Numarası" }]}
          >
              <Input placeholder="Seri numarası" />
          </Form.Item>
        <Form.Item>
          <Space split>
            <Button onClick={onCancel} loading={loading}>
              İptal
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Kaydet
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default OrderItemModal;
