import React from "react";
import Input from "./Input/Input";
import Select from "./Select/Select";
import Title from "./Title/Title";
import Textarea from "./Textarea/Textarea";
import Image from "./Image/Image";
import { Form, Button, Row, Col } from "antd";

function FormBuilder({ fields, initialValues }) {

  const FieldsTmp = ({field,value}) => {
    if (field.elementType == "input") {
      return (
        <Input colspan={field.column} value={value} {...field} />
      );
    } else if (field.elementType == "select") {
      return (
        <Select colspan={field.column} value={value} {...field} />
      );
    } else if (field.elementType == "title") {
      return (
        <Title colspan={field.column} value={value} {...field} />
      );
    } else if (field.elementType == "textarea") {
      return (
        <Textarea colspan={field.column} value={value} {...field} />
      );
    } else if (field.elementType == "image") {
      return (
        <Image colspan={field.column} value={value} {...field} />
      );
    }
    return "";
  }

  return (
    <div>
        <Row gutter={[16, 0]}>
          {fields.map((field,key) => {
            if(field?.childs?.length){
              return <Col key={key} span={24}>
                      <Row gutter={[16, 0]}>
                        <FieldsTmp field={field}/>
                        {
                          Array(initialValues[field?.name]?.length).fill(undefined).map((val,idx) => (
                            <Col key={idx} span={24}>
                              <Row gutter={[24, 0]}>
                                {
                                  field?.childs?.map((fieldChild,keyChild) => {
                                    return <FieldsTmp key={keyChild} field={fieldChild} value={initialValues[field?.name] && initialValues[field?.name][idx] && initialValues[field?.name][idx][fieldChild?.name]}/>
                                  })
                                }
                              </Row>
                            </Col>
                          ))
                        }
                      </Row>
                    </Col>;
            }
            return <FieldsTmp key={key} field={field} value={initialValues[field.name]}/>
          })}
        </Row>
    </div>
  );
}

export default FormBuilder;
