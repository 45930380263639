import ProductSalesReportTable from "../../Tables/product-sales-report"


const ProductSalesReport = () => {

  return (
    <div>
      <ProductSalesReportTable />
    </div>
  )
}

export default ProductSalesReport
