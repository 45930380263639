import React from 'react'
import PropTypes from 'prop-types';
import styles from './Textarea.module.css';
import { Col } from 'antd';

const Textarea = (props) => {
  const {
    label,
    placeholder,
    isDisabled,
    type, 
    isError,
    isErrorText,
    isInfo, 
    name,
    colspan,
    value,
    ...etc
  } = props;
  return (
    <Col xl={colspan} span={24}>
      <div className={styles.formWrapper}>
        <label className={styles.formLabel}>{label}</label>
        <div className={styles.inputWrapper}>
          <textarea name={name} disabled={isDisabled} className={styles.formControl} defaultValue={value} placeholder={placeholder} />
        </div>
      </div>
    </Col>
  )
}

Textarea.propTypes = {
  type: PropTypes.oneOf(["text", "mail", "phone", "password"]),
  label: PropTypes.string,
  name: PropTypes.string,
  colspan: PropTypes.number,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool, 
  isErrorText: PropTypes.string, 
};

Textarea.defaultProps = {
  type: "text",
  label: 'Adınız Soyadınız',
  name: null,
  colspan: null,
  placeholder: 'Giriniz',
  isDisabled: false,
  isError: false, 
  isErrorText: null, 
};

export default Textarea