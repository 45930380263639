import React, { useContext } from "react";
import { Layout, Menu } from "antd";
import { Link, NavLink } from "react-router-dom";
import Routes from "./Routes";
import { LogoutOutlined } from "@ant-design/icons/lib/icons";
import { AuthContext } from "../Context/AuthContext";
const { Sider } = Layout;
function Navigation() {
  const { logout } = useContext(AuthContext);
  return (
    <Sider
      theme="light"
      className="leftNavbar"
    >
      <div className="Logo" />
      <Menu theme="light" mode="inline">
        {Routes.filter((Route) => Route.NavList).map((Route) => {
          const childCounter = Routes.filter(item => item.parent === Route.id);
          return (childCounter.length > 0) ? (
            <Menu.SubMenu key={Route.id} title={Route.Title}>
              {childCounter.map((child,key) => ( 
                <Menu.Item key={child.id}>
                  <NavLink to={child.path}>{child.Title}</NavLink>
                </Menu.Item>
              ))} 
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={Route.id} icon={Route.icon}>
              <NavLink to={Route.path}>{Route.Title}</NavLink>{" "}
            </Menu.Item>
          ) 
        })}
        {/* <Menu.Item onClick={logout} style={{ position: 'absolute', bottom: 0 }}>
          <Menu.Divider/>
          <LogoutOutlined />
          <span>Çıkış</span>
          <Link to="/Login" />
        </Menu.Item> */}
      </Menu>
    </Sider>
  );
}

export default Navigation;
