import React, { useState } from "react";
import FormBuilder from "../Components/Forms/FormBuilder";
import fields from "../jsons/formFields/productEditForm.json";
import { Button, message, Steps, Form } from 'antd';

const ProductEdit = ({ data }) => {
  const { Step } = Steps;
  const [current, setCurrent] = useState(0);
  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  const filteredData = {
    product: [data],
    prices: data.variants && data.variants[0].prices,
    properties: data.properties,
    variants: data.variants,
    medias: data.medias,
  };
  
  const steps = [];
  for(let i = 0; i < fields.length; i++){
    steps.push({ 
      title: fields[i].label, 
      content:<FormBuilder fields={[fields[i]]} initialValues={filteredData} />})
  }
  
  const contentStyle = {
    marginTop: 50,
  };

  return (
    <>
      <Steps current={0}>
        {
          steps.map((item,key) => {
            let status = "";
            if(current === key)
                status = "process";
            else if(current >= key)
                status = "finish";
                
            return <Step key={key} onClick={() => setCurrent(key)} status={status} title={item.title} />
          })
        }
      </Steps>
      <Form className="formBuilder">
        {
          steps.map((item,key) => (
            <div key={key} style={contentStyle} className={["stepContent",current === key ? "" : "hidden"].join(" ")}>
              {item.content}
            </div>
          ))
        }
      </Form>
      
      <div
        style={{
          marginTop: 24,
          textAlign : "right"
        }}
      >
        {current > 0 && (
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => prev()}
          >
            Geri
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            İleri
          </Button>
        )}
        {/* {current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Kayıt Başarılı!')}>
            Tamamla
          </Button>
        )} */}
      </div>
    </>
  );
};

export default ProductEdit;
