import {Collapse, Dropdown, Menu, Table} from "antd";
import useRequest from "../../Hooks/useRequest";
import Filters from "./Filters";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import usePagination from "../../Hooks/usePagination";
import {getOrderReturned} from "../../Service/console";
import React, {useEffect, useState} from "react";
import { formatDate } from "../../utils/date";
import { formatPrice } from "../../utils/price";
import moment from "moment";
const { Panel } = Collapse;
const ReturnedsTable = () => {

  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const [initialValues, setInitialValues] = useState({});
  const [params, setParams] = usePagination({
    EndDate: "",
  });
  const navigate = useNavigate();

  const actionMenu = (Record) => {
    return (
        <Menu>
          <Menu.Item key="4">
            <NavLink to={`/Order/${Record.orderNumber}?isPurchasedOrder=${Record.isPurchasedOrder}`}>Detay</NavLink>
          </Menu.Item>
        </Menu>
    );
  };

  useEffect(() => {
    const data = {};
    for (const [key, value] of Object.entries(params)) {
      if(String(query?.get(key)) !== "undefined" && String(query?.get(key)) !== "null"){
        data[key] = query?.get(key)
      }
    }

    setInitialValues({
      ...data,
      EndDate: data.EndDate ? moment(data.EndDate,"YYYY-MM-DD") : moment(new Date(),"YYYY-MM-DD")
    })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setParams({
        ...params,
        ...initialValues,
        EndDate: initialValues.EndDate ? formatDate(initialValues.EndDate,false,"YYYY-MM-DDTHH:mm:ss") : formatDate(new Date().getDate(),false,"YYYY-MM-DDTHH:mm:ss"),
      })
    },400)
  }, [initialValues]);


  const { data = {}, status, message, update } = useRequest(getOrderReturned, [params]);
  const Columns = [
    {
      key: "orderId",
      title: "Sipariş Id",
      dataIndex: "orderId",
      width: 100,
    },
    {
      key: "orderNumber",
      title: "Sipariş Numarası",
      dataIndex: "orderNumber",
      width: 100,
    },
    {
      key: "customerId",
      title: "Kullanıcı Id",
      dataIndex: "customerId",
      width: 100,
    },
    {
      key: "firstName",
      title: "Adı",
      dataIndex: "firstName",
      width: 100,
    },
    {
      key: "lastName",
      title: "Soyadı",
      dataIndex: "lastName",
      width: 100,
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      width: 100,
    },
    {
      key: "orderCreatedDate",
      title: "Oluşturma Tarihi",
      dataIndex: "orderCreatedDate",
      width: 100,
      render: (date) => formatDate(date),
    },
    {
      title: "",
      fixed: "right",
      dataIndex: "",
      key: "y",
      render: (_, Record) => (
          <Dropdown
              overlay={() => actionMenu(Record)}
              trigger={["click"]}
              placement="bottomLeft"
          >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 23 23"
                style={{
                  transform: "rotate(90deg)",
                  filter:
                      "invert(54%) sepia(1%) saturate(4393%) hue-rotate(176deg) brightness(101%) contrast(86%)",
                  cursor: "pointer",
                }}
            >
              <g fill="none" fillRule="evenodd">
                <path d="M0 0H23V23H0z" />
                <path
                    fill="#666666"
                    fillRule="nonzero"
                    d="M6 10c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm6 0c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm6 0c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
                />
              </g>
            </svg>
          </Dropdown>
      ),
    },
    /*{
      key: "total",
      title: "Tutar",
      dataIndex: "total",
      width: 100,
      render: (price) => formatPrice(price),
    },
    {
      key: "createdAt",
      title: "Oluşturma Tarihi",
      dataIndex: "createdAt",
      render: (date) => formatDate(date),
    },*/
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setParams({ Size: pageSize });
    },
    onChange: (page) => {
      setParams({ Index: page });
    },
    pageSizeOptions: params.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };

  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };

  const onFilter = (values) => {
    const queryString = '?' + new URLSearchParams(values).toString();
    navigate(queryString);
    setParams({
      ...values,
      Index: 1,
      Size: 10,
      EndDate: values.EndDate ? formatDate(values.EndDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined
    });
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Sipariş Item Id',
        dataIndex: 'orderItemId',
        key: 'orderItemId',
      },
      {
        title: 'Ürün Adı',
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: 'Sipariş Durumu',
        dataIndex: 'orderItemStatusText',
        key: 'orderItemStatusText',
      },
      {
        title: 'Kargo Durumu',
        dataIndex: 'shipmentStatusText',
        key: 'shipmentStatusText',
      },
    ];
    const data = [];
    record?.getItemShipmentFilterResponses?.map(item => {
      data.push({
        id: record.orderId,
        orderItemId: item.orderItemId,
        productName: item.productName,
        orderItemStatusText: item.orderItemStatusText,
        shipmentStatusText: item.shipmentStatusText,
      });
    })
    return <Table className="primaryTable" size="small" columns={columns} dataSource={data} pagination={false} />;
  }

  return (
    <>
      <div className="collapseWrapper">
        <Collapse>
          <Panel header="Filtreler">
            <Filters initialValues={initialValues} onSubmit={onFilter} />
          </Panel>
        </Collapse>
      </div>
      <div className="orders__table">
        <Table
            loading={status === "pending"}
            dataSource={status === "success" ? data.data : []}
            columns={Columns}
            pagination={pagination}
            rowKey={"orderId"}
            expandable={{
              expandedRowRender,
              rowExpandable: (record) => record.getItemShipmentFilterResponses.length > 0,
            }}
        />
      </div>
    </>
  );
};

export default ReturnedsTable;
