import { createContext, useState } from 'react'

export const ToggleContext = createContext({
    setIsMobileMenuToggle: () => {},
})

export const ToggleContextProvider = ({ children }) => {
  const [isMobileMenuToggle, setIsMobileMenuToggle] = useState(null)
  
  return (
    <ToggleContext.Provider value={{
      isMobileMenuToggle,
      setIsMobileMenuToggle
    }}>
      {children}
    </ToggleContext.Provider>
  )
}