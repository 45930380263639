import {Button, Space, Input, Form, Modal, Select, Col} from "antd";
import React, {useState} from "react";
import {getProductListWithFilter} from "../Service/console";
const { Option } = Select;

function AddProductToBoxModal({ visible, onFinish, onCancel, loading, initialValues }) {

    const [productList,setProductList] = useState([])
    const [isSelectLoading,setIsSelectLoading] = useState(false)


    const onSearch = async (value) => {
        setIsSelectLoading(true)
        await getProductListWithFilter({
            query: value,
            filterOrderByType: 0,
            pageIndex: 1,
            pageSize: 1000,
            brandIds: []
        }).then(resp => {
            setProductList(resp?.data)
            setIsSelectLoading(false)
        }).catch(err => {
            setIsSelectLoading(false)
            return err
        })
    };

    return (
        <Modal
            title="Kutu Düzenle"
            visible={visible}
            footer={null}
            onCancel={onCancel}
            destroyOnClose
        >
                <Form
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 17 }}
                    layout="horizontal"
                    onFinish={onFinish}
                    initialValues={initialValues}
                >
                    <Form.Item
                        label="Kutu ID: "
                        name="boxId"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="boxId" disabled />
                    </Form.Item>
                    <Form.Item label="Ürün:" name="productId">
                        <Select
                            style={{ width: "100%" }}
                            showSearch
                            loading={isSelectLoading}
                            onSearch={onSearch}
                            placeholder="Lütfen ürün arayın"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }

                        >
                            {productList?.map((list) => (
                                <Option key={list?.productId}>{list?.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 4 }}  style={{justifyContent:"flex-end"}}>
                        <Space split style={{justifyContent:"flex-end"}}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Kaydet
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
        </Modal>
    );
}

export default AddProductToBoxModal;
