import ProductBoxesTable from "../../Tables/product-boxes";

const ProductBoxes = () => {
  return (
    <div>
      <ProductBoxesTable />
    </div>
  )
}

export default ProductBoxes
