import {Form, Input, DatePicker, Button, Switch, Col, Select} from 'antd'
import React from 'react'
import { formatDate } from "../../utils/date";

const { Option } = Select;
const ProviderTypes = {
  data: [
    { id: 0, value: "Kiralarsın",label: "Kiralarsın" },
    { id: 1, value: "Pazarama",label: "Pazarama" },
    { id: 2, value: "Byqee",label: "Byqee" },
    { id: 3, value: "Alya",label: "Alya" },
    { id: 4, value: "Arçelik",label: "Arçelik" },
    { id: 5, value: "Vestel",label: "Vestel" },
    { id: 6, value: "Monsternotebook",label: "Monsternotebook" },
  ]
};
function Filters({ onSubmit,initialValues }) {
  const onFinish = (value) => {
    onSubmit({
      ...value,
      MinOrderCreatedDate: value.MinOrderCreatedDate ? formatDate(value.MinOrderCreatedDate,false,"") : undefined,
      MaxOrderCreatedDate: value.MaxOrderCreatedDate ? formatDate(value.MaxOrderCreatedDate,false,"") : undefined,
    })
  }
  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      initialValues={initialValues}
      layout="horizontal"
      onFinish={onFinish}
    >
      <Form.Item label="Min. Başlangıç Tarihi" name="MinOrderCreatedDate">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Max. Başlangıç Tarihi" name="MaxOrderCreatedDate">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Ürün Kaynağı" name="providerType">
        <Select allowClear style={{ width: "100%" }} placeholder="Please select">
          {ProviderTypes.data.map((status) => (
              <Option key={status.id} value={status.value}>{status.label}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Filtrele
        </Button>
      </Form.Item>
    </Form>
  )
}


export default Filters
