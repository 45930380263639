import ProductsTable from "../../Tables/products"

const Products = () => {

  return (
    <div> 
      <ProductsTable />
    </div>
  )
}

export default Products
