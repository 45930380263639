import apiService from "."

const CommonPath = (path) => `/Common${path}`;

export const orderStatuses = async () => {
  return apiService.get(CommonPath('/order-status-list'))
} 

export const orderItemStatuses = async () => {
  return apiService.get(CommonPath('/order-item-status-list'))
} 

export const paymentStatuses = async () => {
  return apiService.get(CommonPath('/payment-status-list'))
} 