import {Button, Col, Form, Input, Modal, Row, Skeleton, Space, Table} from "antd";
import useRequest from "../../Hooks/useRequest";
import {addAndRemoveBoxProducts, editBox, getBoxList, getBoxProducts, getWarnings} from "../../Service/console";
import usePagination from "../../Hooks/usePagination";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { useEffect, useState} from "react";
import {notify} from "../../utils/notify";
import AddProductToBoxModal from "../../Modals/AddProductToBoxModal";

const ProductBoxesDetailTable = () => {
  const { boxId } = useParams();
  const [params, setParams] = usePagination({
    id: boxId,
  });
  const [isShowAddProductModal, setIsShowAddProductModal] = useState(false);

  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(getBoxProducts, [boxId]);
  const [boxDetail, setBoxDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'))
  const navigate = useNavigate();

  const Columns = [
    {
      key: "productId",
      title: "productId",
      dataIndex: "productId",
    },
    {
      key: "name",
      title: "Ürün Adı",
      dataIndex: "name",
    },
    {
      key: "actions",
      title: "İşlemler",
      render: (_, Record) => {
        console.log(Record)
        return (
            <div>
              <Button
                  type="primary"
                  style={{
                    background: "#dc3545",
                    borderColor: "#dc3545",
                    color: "#ffffff",
                    marginLeft : "10px"
                  }}
                  onClick={() => areYouSureDeleted(Record.productId,Record.name)}
              >
                Sil
              </Button>
            </div>
        );
      },
    },
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setParams({ Size: pageSize });
    },
    onChange: (page) => {
      setParams({ Index: page });
    },
    pageSizeOptions: params.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };

  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };

  useEffect(() => {
    setLoading(true)
    getBoxList({Index: 1, Size: 10,id:boxId})
        .then((res) => {
          setLoading(false)
          setBoxDetail(res.data[0])
        })
        .catch((err) => {
          setLoading(false)
        });
  }, []);


  const onFinishEditBox = (values) => {
    notify("info");
    setLoading(true)
    editBox({...values,userId:user.id,desi:0})
        .then((res) => {
          notify("success");
          setLoading(false)
          navigate("/product-boxes");
        })
        .catch((err) => {
          notify("error");
          setLoading(false)
        });
  };

  const handleDelete = (productId) => {
    //console.log(values)
    notify("info");
    setLoading(true)
    addAndRemoveBoxProducts({boxId:0,productId})
        .then((res) => {
          notify("success");
          setLoading(false)
          update()
        })
        .catch((err) => {
          notify("error");
          setLoading(false)
        });
  };


  const { confirm } = Modal;
  const areYouSureDeleted = (productId,name) => {
    confirm({
      title: <div><b>{name}</b> isimli ürün, <b>{boxDetail.name}</b> kutusundan silinecek. Onaylıyor musunuz?</div>,
      // icon: <ExclamationCircleOutlined />,
      okText: 'Onay',
      cancelText: 'İptal',
      onOk() {
        handleDelete(productId);
      },
    });
  }


  const onFinishAddProduct = (values) => {
    //console.log(values)
    notify("info");
    setLoading(true)
    getWarnings(values.productId).then(res => {
      addAndRemoveBoxProducts(values)
        .then((res) => {
          notify("success");
          setLoading(false)
          setIsShowAddProductModal(false)
          update()
        })
        .catch((err) => {
          notify("error");
          setLoading(false)
        });
    })
    .catch((err) => {
      notify("error");
      setLoading(false)
    });
  };
  const onCancelAddProduct = () => setIsShowAddProductModal(false)

  return (
      <>
        <AddProductToBoxModal initialValues={{boxId}} visible={isShowAddProductModal} onFinish={onFinishAddProduct} onCancel={onCancelAddProduct} loading={loading}/>
        <Row style={{marginTop: "50px"}}>
          <Col md={11} sm={24} xs={24}>
            <Skeleton paragraph={{ rows: 6 }} loading={!boxDetail?.id}>
              <Form
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 17 }}
                  layout="horizontal"
                  onFinish={onFinishEditBox}
                  initialValues={boxDetail}
              >
                <Form.Item
                    label="Kutu ID: "
                    name="id"
                    rules={[{ required: true }]}
                >
                  <Input placeholder="Id" disabled />
                </Form.Item>
                <Form.Item
                    label="Kutu İsmi: "
                    name="name"
                    rules={[{ required: true }]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
                <Form.Item
                    label="Kutu Kodu: "
                    name="code"
                    rules={[{ required: true }]}
                >
                  <Input placeholder="Code" />
                </Form.Item>
                <Form.Item
                    label="En: "
                    name="width"
                    rules={[{ required: true }]}
                >
                  <Input type="number" placeholder="Width" />
                </Form.Item>
                <Form.Item
                    label="Boy: "
                    name="size"
                    rules={[{ required: true }]}
                >
                  <Input type="number" placeholder="Size" />
                </Form.Item>
                <Form.Item
                    label="Yükseklik: "
                    name="height"
                    rules={[{ required: true }]}
                >
                  <Input type="number" placeholder="Height" />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 7 }}  style={{justifyContent:"flex-end"}}>
                  <Space split style={{justifyContent:"flex-end"}}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Kutu Bilgilerini Güncelle
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Skeleton>
          </Col>
          <Col md={1} sm={1} xs={1}>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Col align="right" style={{marginBottom: "30px"}}>
              <Button onClick={setIsShowAddProductModal} type="primary" htmlType="submit" loading={loading}>
                Ürün Ekle
              </Button>
            </Col>
            <Table
                loading={status === "pending"}
                dataSource={status === "success" ? data : []}
                columns={Columns}
                pagination={pagination}
                rowKey={"serialNumber"}
            />
          </Col>
        </Row>
      </>
  );
};

export default ProductBoxesDetailTable;
