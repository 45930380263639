import ShipmentsTable from "../../Tables/Shipments";


const GetShipment = () => {
 
  return (
    <div>
    <ShipmentsTable />

    </div>
  )
}

export default GetShipment
