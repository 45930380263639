import { Navigate, useLocation, Outlet } from 'react-router-dom';
import useAuthentication from '../Hooks/useAuthantication';

export function RequireAuth() {
  const { isLoggedIn } = useAuthentication();
  let location = useLocation();

  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/Login" state={{ from: location }} />;
  }

  return <Outlet />;
}

export default RequireAuth;
