import {Card, Descriptions, Select, Button, Badge, Modal, DatePicker, Table, Collapse} from "antd";
import {useParams, useSearchParams} from "react-router-dom";
import OrderItemModal from "../../Modals/OrderItemModal";
import {
    getPurchasedPrice,
    OrderItemStatus,
    postOrderScore,
    updateItemsPurchased
} from "../../Service/console";
import {
    updateProductSerialNumber,
    updateOrderItemStatus,
    updateOrderStartOrEndDate,
    updateTrackingNumbers
} from "../../Service/order";
import {notify} from "../../utils/notify";
import React, {useState, useContext} from "react";
import {OrderContext} from "../../Context/OrderContex";
import {formatDate} from "../../utils/date";
import {formatPrice} from "../../utils/price";
import ShippingDetailCard from "./ShippingDetailCard";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import JsBarcode from "jsbarcode";
import CargoBarcodeCard from "./CargoBarcodeCard";
import OrderScoreModal from "../../Modals/OrderScoreModal";
import PaymentHistoryModal from "../../Modals/PaymentHistoryModal";

const {Option} = Select;

const { confirm } = Modal;

function ItemCard({items = [], updateOrder, data}) {
    const {orderNumber} = useParams();
    const [item, setItem] = useState(undefined);
    const [updating, setUpdating] = useState(false);
    const [orderStatu, setOrderStatu] = useState(undefined);
    const [selectedStatus, setSelectedStatus] = useState({});
    const {OrderItemStatuses} = useContext(OrderContext);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const openUpdateSerialNumber = (item) => {
        setItem(item);
    };
    const cancelModal = () => setItem(undefined);
    const onSubmit = (values) => {
        setUpdating(true);
        updateProductSerialNumber(orderNumber, [values])
            .then(() => {
                cancelModal();
                updateOrder();
            })
            .finally(() => {
                setUpdating(false);
            });
    };

    const _updateOrderItemStatus = (orderItemId, status) => {
        setUpdating(true);
        updateOrderItemStatus(orderItemId, status).then(() => {
            setUpdating(false);
            updateOrder();
        });
    };

    const _updateOrderStartOrEndDate = (orderItemId, startDate, endDate) => {
        setUpdating(true);
        updateOrderStartOrEndDate(orderItemId, startDate, endDate).then(() => {
            setUpdating(false);
            updateOrder();
        });
    };

    const handleUpdateItemsPurchased = (orderItemId, isOrderItemPurchased) => {
        setUpdating(true);
        updateItemsPurchased({orderItemId, isOrderItemPurchased}).then(() => {
            setUpdating(false);
            updateOrder();
        });
    };

    const _updateTrackingNumbers = (orderItemId, sendTrackingNumber, returnTrackingNumber) => {
        setUpdating(true);
        updateTrackingNumbers(orderItemId, sendTrackingNumber, returnTrackingNumber).then(() => {
            setUpdating(false);
            updateOrder();
        });
    };
    const CargoDirectionTypes = {
        data: [
            { id: 1, value: "Outgoing",label: "Giden Kargo" },
            { id: 2, value: "Incoming",label: "Gelen Kargo" },
        ]
    };

    return (
        <Card title="Ürünler" className="orderItemCard">
            {items.map((item) => (
                <div key={item.id}>
                    <div>
                        <div style={{opacity:"0",position:"absolute",userSelect:"none",pointerEvents:"none",left:"0", right:"0", overflow:"hidden", bottom:"0"}}>
                            <CargoBarcodeCard
                                Item={item}
                                Order={data}
                            />
                        </div>
                        <IsDamaged damaged={item.isDamaged}>
                            <Card
                                key={item.productId}
                                extra={
                                    <div style={{display: 'flex', gap: 12, width : "100%"}}>

                                        <div className="itemActions" style={{justifyContent: 'flex-start',textAlign : 'start'}}>
                                            <DamagedSetting
                                                Item={item}
                                                data={data}
                                                orderNumber={orderNumber}
                                                updateOrder={updateOrder}
                                                startDate={startDate}
                                                setStartDate={setStartDate}
                                                endDate={endDate}
                                                setEndDate={setEndDate}
                                                _updateOrderStartOrEndDate={_updateOrderStartOrEndDate}
                                                handleUpdateItemsPurchased={handleUpdateItemsPurchased}
                                                _updateTrackingNumbers={_updateTrackingNumbers}
                                                openUpdateSerialNumber={openUpdateSerialNumber}

                                            />
                                            <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                                                <Select
                                                    style={{width: '191px'}}
                                                    placeholder="Please select"
                                                    onChange={(value) => {
                                                        setOrderStatu(parseInt(value));
                                                        setSelectedStatus((prevState) => ({
                                                            ...prevState,
                                                            [item.id]: true
                                                        }));
                                                    }}
                                                    defaultValue={OrderItemStatuses.data.find(orderStatus => (orderStatus.id === item.status))?.value}
                                                >
                                                    {OrderItemStatuses.data.map(orderStatus => (
                                                        <Option key={orderStatus.id}>{orderStatus.value}</Option>
                                                    ))}
                                                </Select>
                                                {selectedStatus[item.id] && (
                                                    <Button onClick={() => _updateOrderItemStatus(item.id, orderStatu)}>Sipariş
                                                        Durumu Güncelle</Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                }
                            >
                                <div className="orderItemCardInner">
                                    <img alt={item.productName} src={item.pictureUrl} style={{marginBottom: 24, width: 100, height: 100, objectFit: "cover"}}/>
                                    <div>

                                        <Descriptions
                                            bordered
                                            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                                            size="small"
                                        >
                                            <Descriptions.Item label="Id">
                                                {item.id}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="İsim">
                                                {item.productName}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="sku">
                                                {item.sku}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Adet">
                                                {item.quantity}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Seri numarası">
                                                {item.serialNumber}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Periyot">
                                                {item.variantPrice.priceTypeText}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Hasarlı">
                                                {item.isDamaged}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Başlangıç-Bitiş">
                                                {formatDate(item.startDate)} - {formatDate(item.endDate)}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Sigorta">
                                                {item.fees && item.fees.length > 0 && item.fees[0].type === 5 ? "Var" : "Yok"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Bedel">
                                                {item.fees && item.fees.length > 0 && item.fees[0].type === 5 ? item.fees[0].unitPrice : "-"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Uzatma Siparişi?">
                                                {item.isRepeatOrder === true ? "Evet" : "Hayır"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Uzatılan Sipariş">
                                                {item.repeatOrderNumber}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Toplam Tutar">
                                                {item.totalPriceText} {item.currencyCode}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="İndirim?">
                                                {item.totalDiscount ? "Uygulandı" : "Uygulanmadı"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="İndirim Tutarı">
                                                {item.totalDiscount > 0 ? `${item.totalDiscountText} ${item.currencyCode}` : "-"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Tutar">
                                                {item.unitPriceText} {item.currencyCode}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Satış Durumu">
                                                <div style={{
                                                    backgroundColor: item.isOrderItemPurchased === true ? "var(--success)" : item.isSubscriptionOrder ? "var(--pink)" : "var(--blue)",
                                                    color: "white",
                                                    display : "inline-block",
                                                    padding : "0 20px",
                                                    borderRadius : "8px"
                                                }}>
                                                    {item.isOrderItemPurchased === true ? "Satıldı" : item.isSubscriptionOrder ? "Abonelik" : "Kiralık"}
                                                </div>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </div>
                                </div>
                            </Card>
                        </IsDamaged>

                        <Collapse defaultActiveKey={['1']}>

                            {
                                item?.orderShipments?.map((shipment,index) => {
                                    return (
                                        <Collapse.Panel header={shipment?.cargoDirectionTypeText} key={index}>
                                            <ShippingDetailCard data={shipment} item={item}/>
                                        </Collapse.Panel>
                                    )
                                })
                            }
                        </Collapse>
                    </div>
                </div>
            ))}
            <OrderItemModal
                visible={item}
                onCancel={cancelModal}
                onFinish={onSubmit}
                loading={updating}
                item={item}
            />
        </Card>
    );
}

const DamagedSetting = ({
                            data,Item,openUpdateSerialNumber, orderNumber, updateOrder = () => {
    }, startDate, setStartDate = () => {
    }, endDate, setEndDate = () => {
    }, _updateOrderStartOrEndDate = () => {
    }, handleUpdateItemsPurchased = () => {
    }, _updateTrackingNumbers = () => {
    }
                        }) => {
    const {productId, variantId, id, isDamaged, damagePrice, damageCustomerPrice, payLink} = Item;
    const [showQuote, setShowQuote] = useState(false);
    const [quoteData, setQuoteData] = useState({});
    const [isUpdateTrackingNumberModalOpen, setUpdateTrackingNumberModalOpen] = useState(false);
    const [orderScoreModalVisible, setOrderScoreModalVisible] = useState(false);
    const [paymentHistoryModalVisible, setPaymentHistoryModalVisible] = useState(false);
    const [damageType, setDamageType] = useState(1);

    const [searchParams, setSearchParams] = useSearchParams()

    const onUpdateTrackingNumbersSubmit = (values) => {
        _updateTrackingNumbers(id, values.sendTrackingNumber, values.returnTrackingNumber);
        setUpdateTrackingNumberModalOpen(false);
    };

    const openQuoteModal = ({SerialNumber, CustomerId, ProductId, OrderNumber}) => {
        setShowQuote(true)
        getPurchasedPrice({SerialNumber,CustomerId,ProductId,OrderNumber}).then((res) => {
            setQuoteData(res)
            //notify("success");
        })
            /*.catch(() => {
                notify("error");
            });*/
    }

    const exportCargo = async (cargoKey) => {

        await JsBarcode("#barcode", cargoKey,{
            format : "CODE128"
        })

        /*getBarcode(cargoKey).then(resp => {
            const byteArray = resp?.data?.result
            var binaryString = window.atob(byteArray);

            console.log(binaryString)
        }); */

        const elementHTML = document.querySelector(`#printibleContent_${Item.id}`);
        html2canvas(elementHTML).then(function(canvas) {
            var imgData = canvas.toDataURL(
                'image/png');
            const doc = new jsPDF("landscape", "px", "a7");
            doc.html("<img width='100%' src='" + imgData + "'/>", {
                callback: function(doc) {
                    // Save the PDF
                    doc.save(`kargo_${Item.id}.pdf`);
                },
                filename : "Kargo Çıktısı",
                x: 10,
                y: 10,
                width: 200, //target width in the PDF document
                windowWidth: 750 //window width in CSS pixels
            });
        });

    }


    const Columns = [
        {
            key: "warrantyEndDateText",
            title: "Garanti Bitiş Tarihi",
            dataIndex: "warrantyEndDateText",
        },
        {
            key: "purchasePrice",
            title: "Satış Fiyatı",
            dataIndex: "purchasePrice",
            render: (data) => formatPrice(data || 0),
        },
    ];

    const exportCargoData = Item?.orderShipments?.find(cargoDetail => cargoDetail?.cargoDirectionType === 1);

    const handleConfirmScoreModal = (values) => {
        confirm({
            title: `Formu kaydederek ${damageType === 1 ? 'HASARSIZ' : 'HASARLI'} teslim aldığınızı onaylamış olacaksınız. Devam etmek istiyor musunuz?`,
            okText: 'Onaylıyorum',
            cancelText: 'Vazgeç',
            onOk() {
                notify("info");
                postOrderScore(values)
                    .then(() => {
                        OrderItemStatus({
                            OrderNumber : orderNumber,
                            ProductId : productId,
                            OrderItemId:id,
                            variantId:variantId,
                            IsDamaged:damageType === 2,
                        })
                            .then(() => {
                                notify("success");
                                setOrderScoreModalVisible(false)
                                updateOrder();
                            })
                            .catch((err) => {
                                notify("error");
                            });
                    })
                    .catch(() => {
                        notify("error");
                        setOrderScoreModalVisible(false)
                    });
            },
        });
    }

    const onCancel = () => {
        setOrderScoreModalVisible(false)
    };

    const onFinishOrderScore = (values) => handleConfirmScoreModal(values)

    const handleOpenOrderScoreModal = (type = 1) => {
        // type => 2 hasarlı , 1 hasarsız
        setDamageType(type)
        setOrderScoreModalVisible(true)
    };

    return (
        <>

            <Modal
                    title="Teklifi Gör"
                    visible={showQuote}
                   onCancel={(e) => setShowQuote(false)}
                   footer={null} >
                <Table
                    dataSource={[quoteData.offerValidityPeriod && quoteData] || []}
                    columns={Columns}
                    pagination={false}
                    rowKey={"serialNumber"}
                />
            </Modal>
            <OrderScoreModal visible={orderScoreModalVisible} onFinish={onFinishOrderScore} onCancel={onCancel} data={data}/>
            <PaymentHistoryModal
                visible={paymentHistoryModalVisible}
                onCancel={() => setPaymentHistoryModalVisible(false)}
                order={data}
                product={Item}
            />
            <div className="dates updateDate">
                <div >
                    <DatePicker placeholder="Başlangıç Tarihi" onChange={(date, dateString) => setStartDate(dateString)}/>
                </div>
                <div >
                    <DatePicker placeholder="Bitiş Tarihi" onChange={(date, dateString) => setEndDate(dateString)}/>
                </div>
                <Button
                    onClick={() => {
                        _updateOrderStartOrEndDate(id, startDate, endDate);
                    }}
                >
                    Başlangıç-Bitiş Güncelle
                </Button>
            </div>
            <div className="dates">
                <div>
                    <Button
                        //onClick={handleConfirmDamaged}
                        onClick={() => handleOpenOrderScoreModal(2)}
                        danger
                        type="primary"
                    >

                        Hasarlı Teslim Aldım
                    </Button>
                </div>
                <div>
                    <Button
                        //onClick={handleConfirmNotDamaged}
                        onClick={() => handleOpenOrderScoreModal(1)}
                        className="button-success"
                        type="primary"
                    >

                        Hasarsız Teslim Aldım
                    </Button>
                </div>
                {Item.isSubscriptionOrder && (
                    <Button
                        //onClick={handleConfirmNotDamaged}
                        onClick={() => setPaymentHistoryModalVisible(true)}
                        className="button-warning"
                        type="warning"
                    >

                        Ödeme Geçmişini Göster
                    </Button>
                )}
            </div>
                <Button
                    onClick={() => openUpdateSerialNumber(Item)}
                    style={{marginBottom: 20}}
                >

                    Seri Numarası Güncelle
                </Button>
                {(exportCargoData?.cargoKey && exportCargoData?.cargoDirectionType === 1 && exportCargoData?.shippingCompanyId === 2) && (
                    <Button
                        onClick={() => exportCargo(exportCargoData?.cargoKey)}
                        style={{marginBottom: 20}}
                    >
                        Kargo Çıktısı Al
                    </Button>
                )}
                {/*
            Item.isOrderItemPurchased ? <Button
                onClick={() => {
                    handleUpdateItemsPurchased(id, false);
                }}
                style={{marginBottom: 20}}
                type="primary">

                Kiralık Yap
            </Button> : <Button
                onClick={() => {
                    handleUpdateItemsPurchased(id, true);
                }}
                style={{marginBottom: 20}}
                type="primary">

                Ürünü Sat
            </Button>
            */}

                {Item?.serialNumber && searchParams.get("isPurchasedOrder") === "false" && (
                    <Button
                        onClick={(e) => openQuoteModal({SerialNumber: Item.serialNumber,ProductId:Item.productId,OrderNumber:orderNumber})}
                        style={{marginBottom: 20}}
                        type="orange">
                        Satış Teklifini Gör
                    </Button>
                )}

            {/*
            <ItemDamagedStatusModal
                visible={showDamageStatus}
                onCancel={() => setshowDamageStatus(false)}
                OrderItemId={id}
                OrderNumber={orderNumber}
                variantId={variantId}
                ProductId={productId}
                isDamaged={isDamaged}
                damagePrice={damagePrice}
                damageCustomerPrice={damageCustomerPrice}
                payLink={payLink}
                onFinish={handleSubmitDamage}
            />
            */}
        </>
    );
};

const IsDamaged = ({damaged, children}) => {
    if (damaged) {
        return <Badge.Ribbon color="red" text="Hasarlı">{children}</Badge.Ribbon>;
    } else {
        return children;
    }
};

export default ItemCard;
