import React from 'react'
import PropTypes from 'prop-types';
import styles from './Image.module.css';
import { Col } from 'antd';

const Image = (props) => {
  const {
    label,
    colspan,
    value,
    flex
  } = props;
  return (
    <Col xl={colspan} span={24} flex={flex}>
      <div className={styles.formWrapper}>
        <label className={styles.formLabel}>{label}</label>
        <div className={styles.imageWrapper}>
            <img className={styles.image} src={value} />
        </div>
      </div>
    </Col>
  )
}

Image.propTypes = {
  label: PropTypes.string,
  colspan: PropTypes.number,
};

Image.defaultProps = {
  label: 'Adınız Soyadınız',
  colspan: null,
};

export default Image