import React, { useContext } from "react";
import { Layout as AntLayout } from "antd";
import Navigation from "./Navigation";
import Navbar from "./Navbar";
import Router from "./Router";
import { OrderContextProvider } from "../Context/OrderContex";
import { ToggleContext } from "../Context/ToggleContext";

const { Content, Footer } = AntLayout;
function Layout() {
  const { isMobileMenuToggle } = useContext(ToggleContext)
  return (
    <OrderContextProvider>
      <AntLayout className={[(isMobileMenuToggle ? 'openMenu' : null),"layout"].join(" ")}>
        <Navigation />
        <AntLayout className="rightLayout">
          <Navbar/>
          <Content className="innerContent">
            <Router />
          </Content>
          <Footer className="footer"></Footer>
        </AntLayout>
      </AntLayout>
    </OrderContextProvider>
  );
}

export default Layout;
