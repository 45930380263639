import React from 'react'

function Home() {


  return (
    <div style={{ display: "flex", justifyContent: "space-between"}}>
      <h1>Kirala-admin'e hoşgeldiniz</h1>
    </div>
  )
}

export default Home
