import React, { useContext } from "react";
import { Button, Dropdown, Space, Menu, Row, Col } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons/lib/icons";
import { Link, NavLink } from "react-router-dom";
import { ToggleContext } from "../Context/ToggleContext";
import { AuthContext } from "../Context/AuthContext";

const Navbar = () => {
  const { isMobileMenuToggle, setIsMobileMenuToggle } = useContext(ToggleContext);
  const { logout } = useContext(AuthContext);
  const menu = (
    <Menu>
        <Menu.Item onClick={logout}>
            <LogoutOutlined />
            <span> Çıkış</span>
        </Menu.Item>
    </Menu>
  );
  return (
    <Row className="navbar" justify="space-between" gutter={[0, 10]}>
      <Col>
        <Button
          onClick={() => setIsMobileMenuToggle(!isMobileMenuToggle)}
          className="menuButton"
        >
          <span></span>
        </Button>
      </Col>
      <Col align="right">
        <Dropdown overlay={menu}>
          <Button>
            <UserOutlined /> Hesap
          </Button>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default Navbar;
