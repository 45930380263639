import axios from 'axios';
import { useState, useEffect } from 'react';

const useRequest = (request, params) => {
    const [result, setResult] = useState({ data: undefined, status: 'Empty', message: '' });

    useEffect(() => {
        update();
    }, [...params]);

    const update = () => {
        setResult((prevResult) => ({ ...prevResult, data: undefined, message: '', status: 'pending' }))
        request(...params)
            .then((res) => setResult((prevResult) => ({ ...prevResult, data: res || [], status: 'success' })))
            .catch((err) => setResult((prevResult) => ({ ...prevResult, data: [], message: err, status: 'fail' })));
    }
    return {
        data: result.data,
        status: result.status,
        message: result.message,
        update: update
    };
};

export default useRequest;