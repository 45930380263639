
import CurrentRentalReportDetailTable from "../../../Tables/current-rental-report/detail"


const CurrentRentalReportDetail = () => {

  return (
    <div>
      <CurrentRentalReportDetailTable />
    </div>
  )
}

export default CurrentRentalReportDetail
