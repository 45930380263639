import { Button, Collapse, Row, Col, Table, Tag } from "antd";
import { DownloadOutlined } from "@ant-design/icons/lib/icons";
import useRequest from "../../Hooks/useRequest";
import Filters from "./Filters";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {
  customerOrderReport,
  customerOrderReportExport
} from "../../Service/report";
import { downloadFile } from "../../Service";
import usePagination from "../../Hooks/usePagination";
import { notify } from "../../utils/notify";
import { formatDate } from "../../utils/date";
import moment from "moment";
import {useEffect, useState} from "react";

const { Panel } = Collapse;
const CustomerOrderReportTable = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const [initialValues, setInitialValues] = useState({});
  const navigate = useNavigate();
  const [Attributes, setAttributes] = usePagination({
    Id: undefined,
    FirstName: "",
    LastName: "",
    Email: "",
    phone: "",
    IsOrderedCustomer: "",
    EndDate: "",
    StartDate: "",
  });


  useEffect(() => {
    const data = {};
    for (const [key, value] of Object.entries(Attributes)) {
      if(String(query?.get(key)) !== "undefined" && String(query?.get(key)) !== "null"){
        data[key] = query?.get(key)
      }
    }

    setInitialValues({
      ...data,
      StartDate: data.StartDate ? moment(data.StartDate,"YYYY-MM-DD") : undefined,
      EndDate: data.EndDate ? moment(data.EndDate,"YYYY-MM-DD") : undefined
    })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setAttributes({
        ...Attributes,
        ...initialValues,
        StartDate: initialValues.StartDate ? formatDate(initialValues.StartDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
        EndDate: initialValues.EndDate ? formatDate(initialValues.EndDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined
      })
    },400)
  }, [initialValues]);


  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(customerOrderReport, [Attributes]);

  const getStatusColor = (status) => status ? "geekblue" : "volcano"

  const Columns = [
    {
      key: "id",
      title: "Id",
      dataIndex: "id",
      width: 70,
    },
    {
      key: "firstName",
      title: "Adı",
      dataIndex: "firstName",
      width: 100,
    },
    {
      key: "lastName",
      title: "Soyadı",
      dataIndex: "lastName",
      width: 100,
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      width: 100,
    },
    {
      key: "phone",
      title: "Tel no",
      dataIndex: "phone",
      width: 100,
    },
    {
      key: "isActive",
      title: "Durum",
      dataIndex: "isActive",
      render: (status) => (
        <Tag color={getStatusColor(status)}>
          {status ? "Aktif" : "Pasif"}
        </Tag>
      ),
      width: 50,
    },
    {
      key: "isEmailOptin",
      title: "Email İzni",
      dataIndex: "isEmailOptin",
      render: (status) => (
        <Tag color={getStatusColor(status)}>
          {status ? "Var" : "Yok"}
        </Tag>
      ),
      width: 50,
    },
    {
      key: "isSmsOptin",
      title: "Sms İzni",
      dataIndex: "isSmsOptin",
      render: (status) => (
        <Tag color={getStatusColor(status)}>
          {status ? "Var" : "Yok"}
        </Tag>
      ),
      width: 50,
    },
    {
      key: "isCallOption",
      title: "Arama İzni",
      dataIndex: "isCallOption",
      render: (status) => (
        <Tag color={getStatusColor(status)}>
          {status ? "Var" : "Yok"}
        </Tag>
      ),
      width: 50,
    },
    {
      key: "isDeleted",
      title: "Silindi",
      dataIndex: "isDeleted",
      render: (isDeleted) => isDeleted && <Tag color={"volcano"}>Silindi</Tag>,
      width: 50,
    },
    {
      key: "createdAt",
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      render: (date) => formatDate(date),
    },
    // {
    //   title: "Detay",
    //   dataIndex: "",
    //   fixed: "right",
    //   key: "x",
    //   render: (_, Record) => (
    //     <NavLink to={`/Customer/${Record.id}`}>
    //       <Button type="primary">Detay</Button>
    //     </NavLink>
    //   ),
    // },
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setAttributes({ Size: pageSize });
    },
    onChange: (page) => {
      setAttributes({ Index: page });
    },
    pageSizeOptions: Attributes.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };
  const onFilter = (values) => {
    const queryString = '?' + new URLSearchParams(values).toString();
    navigate(queryString);
    setAttributes({
      ...values,
      Index: 1,
      Size: 10
    });
  };

  const _demandedExport = () => {
    notify("info");
    customerOrderReportExport(Attributes)
      .then((res) => {
        downloadFile(res)
        notify("success");
      })
      .catch(() => {
        notify("error");
      });
  }; 

  return (
    <>
      <Collapse>
        <Panel header="Filtreler">
          <Filters initialValues={initialValues} onSubmit={onFilter} />
        </Panel>
      </Collapse>
      <Row>
        <Col style={{ padding: "24px 24px 24px 0", right: 0, marginLeft: "auto"}}>
          <Button type="primary" shape="round" icon={<DownloadOutlined />}
            onClick={_demandedExport}
          >
            Müşteri Verileri Raporu Export
          </Button>
        </Col>
      </Row>
      <Table
        loading={status === "pending"}
        dataSource={status === "success" ? data.data : []}
        columns={Columns}
        pagination={pagination}
        rowKey={"id"}
      />
    </>
  );
};

export default CustomerOrderReportTable;
