import { Button, Collapse, Row, Col, Table } from "antd";
import { DownloadOutlined } from "@ant-design/icons/lib/icons";
import useRequest from "../../Hooks/useRequest";
import Filters from "./Filters";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {
  orderStatisticReport,
  orderStatisticReportExport
} from "../../Service/report";
import { downloadFile } from "../../Service";
import usePagination from "../../Hooks/usePagination";
import { notify } from "../../utils/notify";
import { formatDate } from "../../utils/date";
import {useEffect, useState} from "react";
import moment from "moment/moment";
import {formatPrice} from "../../utils/price";

const { Panel } = Collapse;
const OrderStatisticReportTable = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const [initialValues, setInitialValues] = useState({});
  const navigate = useNavigate();
  const [Attributes, setAttributes] = usePagination({
    OrderCount: undefined,
    MinStartDate:"",
    MaxStartDate:"",
    MinEndDate:"",
    MaxEndDate:"",
    OrderStartCreatedDate:"",
    OrderEndCreatedDate:"",
  });


  useEffect(() => {
    const data = {};
    for (const [key, value] of Object.entries(Attributes)) {
      if(String(query?.get(key)) !== "undefined" && String(query?.get(key)) !== "null"){
        data[key] = query?.get(key)
      }
    }

    setInitialValues({
      ...data,
      MinStartDate: data.MinStartDate ? moment(data.MinStartDate,"YYYY-MM-DD",false) : undefined,
      MaxStartDate: data.MaxStartDate ? moment(data.MaxStartDate,"YYYY-MM-DD",false) : undefined,
      MinEndDate: data.MinEndDate ? moment(data.MinEndDate,"YYYY-MM-DD",false) : undefined,
      MaxEndDate: data.MaxEndDate ? moment(data.MaxEndDate,"YYYY-MM-DD",false) : undefined,
      OrderStartCreatedDate: data.OrderStartCreatedDate ? moment(data.OrderStartCreatedDate,"YYYY-MM-DD",false) : undefined,
      OrderEndCreatedDate: data.OrderEndCreatedDate ? moment(data.OrderEndCreatedDate,"YYYY-MM-DD",false) : undefined,
    })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setAttributes({
        ...Attributes,
        ...initialValues,
        MinStartDate: initialValues.MinStartDate ? formatDate(initialValues.MinStartDate,false,"YYYY-MM-DD",false) : undefined,
        MaxStartDate: initialValues.MaxStartDate ? formatDate(initialValues.MaxStartDate,false,"YYYY-MM-DD",false) : undefined,
        MinEndDate: initialValues.MinEndDate ? formatDate(initialValues.MinEndDate,false,"YYYY-MM-DD",false) : undefined,
        MaxEndDate: initialValues.MaxEndDate ? formatDate(initialValues.MaxEndDate,false,"YYYY-MM-DD",false) : undefined,
        OrderStartCreatedDate: initialValues.OrderStartCreatedDate ? formatDate(initialValues.OrderStartCreatedDate,false,"YYYY-MM-DD",false) : undefined,
        OrderEndCreatedDate: initialValues.OrderEndCreatedDate ? formatDate(initialValues.OrderEndCreatedDate,false,"YYYY-MM-DD",false) : undefined,
      })
    },400)
  }, [initialValues]);

  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(orderStatisticReport, [Attributes]);
  const Columns = [
    {
      key: "orderCount",
      title: "Sipariş Sayısı",
      dataIndex: "orderCount",
      width: 70,
    },
    {
      key: "uniueCustomerCount",
      title: "Tekil Müşteri Sayısı",
      dataIndex: "uniueCustomerCount",
      width: 120,
    },
    {
      key: "totalAmount",
      title: "Toplam Tutar",
      dataIndex: "totalAmount",
      width: 120,
    },
    {
      key: "totalOrderItem",
      title: "Toplam Ürün",
      dataIndex: "totalOrderItem",
      width: 120,
    },
    {
      key: "totalOrderItemByWeekly",
      title: "Haftalık Ürün",
      dataIndex: "totalOrderItemByWeekly",
      width: 120,
    },
    {
      key: "totalOrderItemBy3Monthly",
      title: "3 Aylık Ürün",
      dataIndex: "totalOrderItemBy3Monthly",
      width: 120,
    },
    {
      key: "totalOrderItemByMonthly",
      title: "Aylık Ürün",
      dataIndex: "totalOrderItemByMonthly",
      width: 70,
    },
    {
      key: "orderRentalTotal",
      title: "Toplam Kiralama Tutarı",
      dataIndex: "orderRentalTotal",
      render: (date) => formatPrice(date),
    },
    {
      key: "puchaseOrderTotal",
      title: "Toplam Satın Alma Tutarı",
      dataIndex: "puchaseOrderTotal",
      render: (date) => formatPrice(date),
    },
    {
      key: "subscriptionMonthlyTotal",
      title: "Aylık Abonelik Tutarı",
      dataIndex: "subscriptionMonthlyTotal",
      render: (date) => formatPrice(date),
    },
    {
      key: "interestTotal",
      title: "Vade Farkı Toplamı",
      dataIndex: "interestTotal",
      render: (date) => formatPrice(date),
    },
    {
      key: "minStartDate",
      title: "Min Başlangıç Tarihi",
      dataIndex: "minStartDate",
      render: (date) => formatDate(date),
    },
    {
      key: "maxStartDate",
      title: "Max Başlangıç Tarihi",
      dataIndex: "maxStartDate",
      render: (date) => formatDate(date),
    },
    {
      key: "minEndDate",
      title: "Min Bitiş Tarihi",
      dataIndex: "minEndDate",
      render: (date) => formatDate(date),
    },
    {
      key: "maxEndDate",
      title: "Max Bitiş Tarihi",
      dataIndex: "maxEndDate",
      render: (date) => formatDate(date),
    },
    // {
    //   title: "Detay",
    //   dataIndex: "",
    //   fixed: "right",
    //   key: "x",
    //   render: (_, Record) => (
    //     <NavLink to={`/Customer/${Record.id}`}>
    //       <Button type="primary">Detay</Button>
    //     </NavLink>
    //   ),
    // },
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setAttributes({ Size: pageSize });
    },
    onChange: (page) => {
      setAttributes({ Index: page });
    },
    pageSizeOptions: Attributes.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };
  const onFilter = (values) => {
    const queryString = '?' + new URLSearchParams(values).toString();
    navigate(queryString);
    setAttributes({
      ...values,
      Index: 1,
      Size: 10
    });
  };

  // const _demandedExport = () => {
  //   notify("info");
  //   orderStatisticReportExport()
  //     .then((res) => {
  //       downloadFile(res)
  //       notify("success");
  //     })
  //     .catch(() => {
  //       notify("error");
  //     });
  // }; 

  return (
    <>
      <Collapse>
        <Panel header="Filtreler">
          <Filters initialValues={initialValues} onSubmit={onFilter} />
        </Panel>
      </Collapse>
      {/* <Row>
        <Col style={{ padding: "24px 24px 24px 0", right: 0, marginLeft: "auto"}}>
          <Button type="primary" shape="round" icon={<DownloadOutlined />}
            onClick={_demandedExport}
          >
            Sipariş İstatikleri Raporu Export
          </Button>
        </Col>
      </Row> */}
      <Table
        loading={status === "pending"}
        dataSource={status === "success" ? Object.keys(data).map((key) => data).filter((item, index, self) =>
    index === self.findIndex((t) => t.OrderCount === item.OrderCount)
  ) : []}
        columns={Columns}
        rowKey={"createdAt"}
      />
    </>
  );
};

export default OrderStatisticReportTable;
