import { Descriptions } from 'antd'
import React from 'react';
import {formatDate} from "../../utils/date";

function ShippingDetailCard(props) {
    const {data} = props;

    const CargoDirectionTypes = {
        data: [
            { id: 1, value: "Outgoing",label: "Giden Kargo" },
            { id: 2, value: "Incoming",label: "Gelen Kargo" },
        ]
    };

    const list = [
        {
            source: "cargoDirectionType",
            title: "Kargo Gönderim Tipi",
            value: "cargoDirectionType",
            render: (val) => CargoDirectionTypes.data?.find(item => item.id === val)?.label,
        },
        {
            source: "cargoKey",
            title: "Kargo Key",
            value: "cargoKey",
            render: (val) => val,
        },
        {
            source: "cargoTrackingNumber",
            title: "Kargo Takip Numarası",
            value: "cargoTrackingNumber",
            render: (val) => val,
        },
        {
            source: "cargoTrackingUrl",
            title: "Kargo Takip Linki",
            value: "cargoTrackingUrl",
            render: (val) => <a href={val} className="ant-btn ant-btn-primary" target="_blank">Git</a>,
        },
        {
            source: "createdAt",
            title: "Kargo Açılma Tarihi",
            value: "createdAt",
            render: (date) => formatDate(date),
        },
        {
            source: "deliveryDate",
            title: "Teslim Tarihi",
            value: "deliveryDate",
            render: (date) => formatDate(date),
        },
        {
            source: "quantity",
            title: "Adet",
            value: "quantity",
            render: (val) => val,
        },
        {
            source: "shipmentRefNumber",
            title: "Gönderi Ref Numarası",
            value: "shipmentRefNumber",
            render: (val) => val,
        },
        {
            source: "shippedDate",
            title: "Sevk Tarihi",
            value: "shippedDate",
            render: (date) => formatDate(date),
        },
        {
            source: "shippingCompanyName",
            title: "Nakliye Şirketi",
            value: "shippingCompanyName",
            render: (val) => val,
        },
        {
            source: "shippingDeliveryStatusText",
            title: "Nakliye Teslimat Durumu",
            value: "shippingDeliveryStatusText",
            render: (val) => val,
        },
    ]
    return (
        <div className="order-detail-description-card">
            <Descriptions
                //title={CargoDirectionTypes.data?.find(item => item.id === data.cargoDirectionType)?.label}
                bordered
                column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                size="small"
            >
                {list.map(listItem =>
                    <Descriptions.Item key={listItem.source} label={listItem.title}>{listItem.render(data[listItem.value])}</Descriptions.Item>
                )}
            </Descriptions>
        </div>

    )
}

export default ShippingDetailCard