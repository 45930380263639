import React from 'react'
import PropTypes from 'prop-types';
import styles from './Select.module.css';
import { Col } from 'antd';

const Select = (props) => {
  const { label, placeholder, isError, errorText, data, dataValue, dataLabel,colspan,...etc } = props;
  return (
    <Col span={colspan}>
      <div className={styles.formWrapper}>
        <label className={styles.formLabel}>{label}</label>
        <div className={styles.SelectWrapper}>
            <select {...etc}>
                <option value="">{placeholder}</option>
                {data.map((value, key) => (
                    <option key={value[dataValue]} value={value[dataValue]}>
                    {value[dataLabel]}
                    </option>
                ))}
            </select>
        </div>
      </div>
    </Col>
  )
}

Select.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    isError: PropTypes.bool,
    errorText: PropTypes.string,
    data: PropTypes.array,
    dataValue: PropTypes.string,
    dataLabel: PropTypes.string,
};

Select.defaultProps = {
    label: "Adres Bilgisi",
    placeholder: "Seçiniz",
    isError: false,
    errorText: "Lütfen seçim yapınız.",
    data: [],
    dataValue: "value",
    dataLabel: "label",
};

export default Select