import React from 'react'
import { Form, Button, Input } from 'antd'

function Login({ onSubmit, status }) {
  return (
    <Form onFinish={onSubmit}  className="login-form">
      <h2>Giriş</h2>
      <Form.Item
        label="email"
        name="email"
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input type={"email"} />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>
      <Button type="primary" className='mt-2' htmlType="submit" loading={status === 'pending'}>
        Submit
      </Button>
    </Form>
  )
}

export default Login
