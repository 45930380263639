import OrderInsuranceReportTable from "../../Tables/order-insurance-report"


const OrderInsuranceReport = () => {

  return (
    <div>
      <OrderInsuranceReportTable />
    </div>
  )
}

export default OrderInsuranceReport
