import CustomerOrderReportTable from "../../Tables/customer-order-report"


const CustomerOrderReport = () => {

  return (
    <div>
      <CustomerOrderReportTable />
    </div>
  )
}

export default CustomerOrderReport
