import {Button, Space, Input, Form, Modal, Select, InputNumber} from "antd";
import React from "react";
const { Option } = Select;

function ReturnedShipmentModal({ visible, onFinish, onCancel, loading, code }) {

    const ProviderTypes = {
        data: [
            { id: 1, value: "BoltCargo",label: "Bolt Kargo" },
            { id: 2, value: "YurticiCargo",label: "Yurtiçi Kargo" },
            { id: 3, value: "HepsiJetCargo",label: "Hepsi Jet Kargo" },
        ]
    };

    return (
        <Modal
            title="İade Kodu"
            visible={visible}
            footer={null}
            onCancel={onCancel}
            destroyOnClose
        >
            {code !== '' ? <div className="cargoCode">{code}</div> : <>
                <Form
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 17 }}
                    layout="horizontal"
                    onFinish={onFinish}
                >

                    <Form.Item label="Kargo Firması: " name="providerType" rules={[{ required: true, message: "Kargo firması zorunludur" }]}>
                        <Select style={{ width: "100%" }} placeholder="Lütfen seçim yapın">
                            {ProviderTypes.data.map((item) => (
                                <Option key={item.id} value={item.value}>{item.label}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 10 }}  style={{justifyContent:"flex-end"}}>
                        <Space split>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                İade Kargo Kodu Oluştur
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </>}
        </Modal>
    );
}

export default ReturnedShipmentModal;
