import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../Context/AuthContext';
import { logout, isLoggedin as serviceLoggedIn } from '../Service'

export default function useAuthentication() {
  const { LoginStatu } = useContext(AuthContext)
  const [isLoggedIn, setisLoggedIn] = useState(serviceLoggedIn() && LoginStatu === 'success');
  useEffect(() => {
    if (
      !isLoggedIn &&
      serviceLoggedIn() &&
      LoginStatu === 'success'
    ) {
      Login()
    } else if (isLoggedIn && LoginStatu === 'empty') {
      logout();
    }
  }, [LoginStatu, isLoggedIn]);
  const Login = () => {
    setisLoggedIn(true);
  };
  const Logout = () => {
    setisLoggedIn(false);
  };
  return {
    isLoggedIn,
    Login,
    Logout,
  };
}
