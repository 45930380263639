import ProductBoxesDetailTable from "../../../Tables/product-boxes/detail";

const ProductBoxesDetail = () => {
  
  return (
    <div>
      <ProductBoxesDetailTable />
    </div>
  );
};

export default ProductBoxesDetail;
